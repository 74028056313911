import { AssetIdentifierType } from 'shared/models/my-loads/assets/asset-identifier-type.enum';
import { Inject, Service } from 'typedi';
import { Observable } from 'rxjs/Observable';
import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { SpotBidDetailV2 } from 'shared/models/spot-bids/spot-bid-detail-v2.model';
import { SpotBidDetailV3 } from 'shared/models/spot-bids/v3/spot-bid-detail-v3.model';
import { SpotBidShipmentDetailsV3 } from 'shared/models/spot-bids/v3/spot-bid-shipment-details-v3.model';
import { ExtendedLoad } from 'shared/models/loads/extended-load.model';
import { ResultSet } from 'shared/models/result-set.model';
import { Driver } from 'shared/models/contacts/driver.model';
import { InvoiceComment } from 'shared/models/invoice/invoice-comment.model';
import { CreateConversationCommentRequest, MarkConversationReadByRelatedEntityRequest, ReadConversationByRelatedEntityRequest } from 'shared/models/conversations/conversation.model';
import moment from 'moment';
import { API_DATE_FORMAT, Headers } from '../globals/constants';
import { DriverLog } from 'shared/models/loads/driver-logs/driver-log.model';
import { ExtendedStop } from 'shared/models/loads/stops/extended-stop.model';
import { LoadSummary as LoadSummaryV2, LoadSummaryUpdate, ShipmentStop, StopUpdate } from '@features/my-loads-v2/types';
import { LoadSummary } from '@shared/models/loads/load-summaries/load-summary.model';

@Service()
export class GatewayRepository extends CacheableRepository {
  @Inject('apiConfig.gatewayAPI')
  protected baseUrl;

  public getSpotBidDetails(shipmentId: string, spotBidId: string, carrierCode: string): Observable<SpotBidDetailV2> {
    return this.get(`/Shipments/${shipmentId}/SpotBids/${spotBidId}/Carriers/${carrierCode}`)
      .map(detail => new SpotBidDetailV2(detail));
  }

  public getSpotBidDetailsV3(shipmentId: string, spotBidId: string, carrierCode: string): Observable<SpotBidDetailV3> {
    return this.get(`/Shipments/${shipmentId}/SpotBids/${spotBidId}/Carriers/${carrierCode}`)
      .map(detail => new SpotBidDetailV3(detail));
  }

  public getSpotBidShipmentDetails(shipmentId: string, spotBidId: string, carrierCode: string): Observable<SpotBidShipmentDetailsV3> {
    return this.get(`/Shipments/${shipmentId}/SpotBids/${spotBidId}/ShipmentDetails/Carriers/${carrierCode}`)
      .map(detail => new SpotBidShipmentDetailsV3(detail));
  }

  public addCarrierBid(shipmentId: string, spotBidId: string, spotBidCarrierId: string, carrierBidCreateModel: CarrierBidCreateModel): Observable<string> {
    return this.post(`/Shipments/${shipmentId}/SpotBids/${spotBidId}/SpotBidCarriers/${spotBidCarrierId}/CarrierBids`,
      carrierBidCreateModel
    );
  }

  public updateCarrierBid(shipmentId: string, spotBidId: string, spotBidCarrierId: string, carrierBidId: string, carrierBidUpdateModel: CarrierBidUpdateModel): Observable<string> {
    return this.put(`/Shipments/${shipmentId}/SpotBids/${spotBidId}/SpotBidCarriers/${spotBidCarrierId}/CarrierBids/${carrierBidId}`,
      carrierBidUpdateModel
    );
  }

  public deleteCarrierBid(shipmentId: string, spotBidId: string, spotBidCarrierId: string, carrierBidId: string): Observable<string> {
    return this.delete(`/Shipments/${shipmentId}/SpotBids/${spotBidId}/SpotBidCarriers/${spotBidCarrierId}/CarrierBids/${carrierBidId}`);
  }

  public getSpotBidCarrierDocuments(shipmentId: string, spotBidId: string, carrierCode: string): Observable<SpotBidDocumentSummaryV3[]> {
    return this.get(`/Shipments/${shipmentId}/SpotBids/${spotBidId}/Carriers/${carrierCode}/Documents`)
  }

  public uploadSpotBidCarrierDocuments(shipmentId: string, spotBidId: string, carrierCode: string, uploadData: FormData): Observable<boolean> {
    const headers = { [Headers.CONTENT_TYPE]: 'multipart/form-data' };

    return this.postWithMultipart(`/Shipments/${shipmentId}/SpotBids/${spotBidId}/Carriers/${carrierCode}/Documents`, uploadData, headers);
  }

  public downloadSpotBidCarrierDocument(shipmentId: string, spotBidId: string, carrierCode: string, document: SpotBidDocumentSummaryV3): Observable<Blob> {
    return this.getBlob(`/Shipments/${shipmentId}/SpotBids/${spotBidId}/Carriers/${carrierCode}/Documents/${document.documentId}`);
  }

  public deleteSpotBidCarrierDocument(shipmentId: string, spotBidId: string, carrierCode: string, document: SpotBidDocumentSummaryV3): Observable<void> {
    return this.delete(`/Shipments/${shipmentId}/SpotBids/${spotBidId}/Carriers/${carrierCode}/Documents/${document.documentId}`);
  }

  public searchLoads(searchParams: MyLoadsSearchRequest): Observable<ResultSet<LoadSummary>> {
    return this.post(`/loads`, searchParams).map(response => new ResultSet(response, json => new LoadSummary(json)));
  }

  public getLoadDetails(loadNumber: number, bookType?: string, carrierCode?: string): Observable<ExtendedLoad> {
    const queryString = this.generateQueryString({ bookType, carrierCode });
    return this.get(`/loads/${loadNumber}${queryString}`).map((load) => new ExtendedLoad(load));
  }

  public searchAssetIdentifiers(identifierType: AssetIdentifierType, searchText: string): Observable<string[]> {
    return this.post('/TrackingIdentifiers/Search', { partialTrackingIdentifier: searchText, identifierType });
  }

  public editTrailer(loadNumber: number, sequenceNumber: number, trailerNumber: string) {
    return this.patch(`/CarrierBooks/loads/${loadNumber}/books/${sequenceNumber}/TrailerNumber`, { value: trailerNumber });
  }

  public editTractor(loadNumber: number, sequenceNumber: number, tractorNumber: string) {
    return this.patch(`/CarrierBooks/loads/${loadNumber}/books/${sequenceNumber}/TractorNumber`, { value: tractorNumber });
  }

  public assignDriverToLoad(loadNumber: number, bookSequenceNumber: number, driver: Driver): Observable<void> {
    const driverUrl = `/Drivers/loads/${loadNumber}/books/${bookSequenceNumber}/driver`;
    return this.put(driverUrl, JSON.stringify(driver));
  }

  public submitCheckIn(load: ExtendedLoad): Observable<ExtendedLoad> {
    const url = `/CarrierBooks/Loads/${load.number}`;
    return this.patch(url, JSON.stringify(load.toJson()))
      .map(book => new ExtendedLoad(book));
  }

  public submitCheckInV2(load: LoadSummaryV2, carrierCode: string): Observable<void> {
    const url = `/CarrierBooks/Loads/${load.loadNumber}`;
    var loadSummaryUpdate = new LoadSummaryUpdate(load, carrierCode);
    loadSummaryUpdate.activeBooking.expectedEmptyAddress.updateAddressForStop(load.firstPick);
    loadSummaryUpdate.activeBooking.driverEmpty = true;
    return this.patch(url, JSON.stringify(loadSummaryUpdate));
  }

  public getCdmsTickets() {
    return this.get('/CDMSTickets');
  }

  public createDriverLog(loadNumber: number, sequenceNumber: number, driverLog: DriverLog): Observable<DriverLog> {
    const driverLogUrl = `/CarrierBooks/loads/${loadNumber}/books/${sequenceNumber}/driverlogs`;

    return this.post(driverLogUrl, JSON.stringify(driverLog.toPostJson()));
  }

  public createDriverLogWithStop(loadNumber: number, sequenceNumber: number, stopNumber: number, driverLog: DriverLog): Observable<void> {
    const driverLogUrl = `/CarrierBooks/loads/${loadNumber}/books/${sequenceNumber}/stops/${stopNumber}/driverlogs`;
    return this.post(driverLogUrl, JSON.stringify(driverLog.toPostJson()));
  }

  public getAccessorialComments(carrierCode: string, accessorialRequestId: string): Observable<InvoiceComment[]> {
    return this.get(`/carriers/${carrierCode}/accessorials/${accessorialRequestId}/comments`,)
      .map(json => GatewayRepository.mapConversationResponseToInvoiceComments(json, carrierCode));
  }

  public getComments(carrierCode: string, invoiceId: number, relatedEntityType: string): Observable<InvoiceComment[]> {
    const queryString = this.generateQueryString(new ReadConversationByRelatedEntityRequest(invoiceId.toString(), relatedEntityType));
    return this.get(`/carriers/${carrierCode}/invoices/${invoiceId}/comments${queryString}`,)
      .map(json => GatewayRepository.mapConversationResponseToInvoiceComments(json, carrierCode));
  }

  public addComment(carrierCode: string, invoiceId: number, relatedEntityType: string, message: string, user: string): Observable<InvoiceComment> {
    const request: CreateConversationCommentRequest = {
      relatedEntityId: invoiceId.toString(),
      relatedEntityType: relatedEntityType,
      createdByUser: user,
      createdByParty: carrierCode,
      comment: message
    }

    return this.post(`/carriers/${carrierCode}/invoices/${invoiceId}/comments`, request)
      .map(json => GatewayRepository.mapConversationResponseToInvoiceComments(json, carrierCode)[0]);
  }

  public markCommentRead(carrierCode: string, invoiceId, relatedEntityType: string, user: string): Observable<InvoiceComment> {
    const request: MarkConversationReadByRelatedEntityRequest = {
      relatedEntityId: invoiceId.toString(),
      relatedEntityType: relatedEntityType,
      readByUser: user,
      readByParty: carrierCode
    }

    return this.put(`/carriers/${carrierCode}/invoices/${invoiceId}/comments/read`, request)
      .map(json => GatewayRepository.mapConversationResponseToInvoiceComments(json, carrierCode)[0]);
  }

  public updateStopDetails(load: ExtendedLoad, stop: ExtendedStop): Observable<ExtendedStop> {
    const url = `/CarrierBooks/loads/${load.number}/books/${load.loadBook.sequenceNumber}/stops/${stop.number}`;
    return this.patch(url,
      JSON.stringify(
        {
          bookType: load?.loadBook?.bookType ? load.loadBook.bookType : null,
          carrierId: load?.loadBook?.carrier?.code ? load.loadBook.carrier.code : null,
          ...stop.toJson()
        }
      ))
      .map(stopJSON => new ExtendedStop(stopJSON));
  }

  public updateStopDetailsV2(load: LoadSummaryV2, carrierCode: string, stop: ShipmentStop,
    departDateTime?: Date, arriveDateTime?: Date, lateReason?: string): Observable<void> {
    const url = `/CarrierBooks/loads/${load.loadNumber}/books/${load.bookId}/stops/${stop.stopNumber}`;
    let stopUpdate = new StopUpdate(Number(load.loadNumber), stop, departDateTime, arriveDateTime, lateReason);
    return this.patch(url,
      JSON.stringify(
        {
          bookType: load.bookType,
          carrierId: carrierCode,
          ...stopUpdate
        }
      ));
  }

  public updateEventLog(loadNumber: number, eventLogId: string, updateEventLogInfo: UpdateEventLogInfo): Observable<void> {
    const updateEventLogUrl = `/CarrierBooks/loads/${loadNumber}/eventlogs/${eventLogId}/update`;
    return this.patch(updateEventLogUrl, updateEventLogInfo);
  }

  public closeEventLog(loadNumber: number, eventLogId: string, closeEventLogInfo: CloseEventLogInfo): Observable<void> {
    const updateEventLogUrl = `/CarrierBooks/loads/${loadNumber}/eventlogs/${eventLogId}/close`;
    return this.patch(updateEventLogUrl, closeEventLogInfo);
  }

  public createEventLog(loadNumber: number, createEventLogInfo: CreateEventLogInfo): Observable<void> {
    const updateEventLogUrl = `/CarrierBooks/loads/${loadNumber}/eventlogs`;
    return this.post(updateEventLogUrl, createEventLogInfo);
  }

  public static mapConversationResponseToInvoiceComments(response, carrierCode): InvoiceComment[] {
    if (response?.conversation === null) {
      return null;
    }

    return response.conversation.comments.map(comment =>
      new InvoiceComment({
        messageId: comment.conversationCommentId,
        username: comment.createdBy,
        message: comment.comment,
        createdDateTime: moment(comment.createdDateTime).format(API_DATE_FORMAT),
        sourceApplication: comment.sourceApplication,
        hasBeenViewed: comment.readLogs.some(rl => rl.readByParty === carrierCode),
        createdByParty: comment.createdByParty
      })
    );
  }
}
