import classNames from 'classnames';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { DataViewFormat } from 'shared/enums/data-view.enum';

interface Props {
  className?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  viewFormat: DataViewFormat;
}

export const ViewSelectors = ({ className, onChange, viewFormat }: Props) => {
  const { t } = useTranslation();

  return (
    <fieldset className={classNames(className, 'no-wrap view-selectors')}>
      <legend className="visually-hidden">{t('DISPLAY_RESULTS_AS')}</legend>
      <span className="grid-view-selector selector">
        <input
          id="rbGridView"
          name="view-selector"
          type="radio"
          className="visually-hidden"
          onChange={onChange}
          value={DataViewFormat.Grid}
          checked={viewFormat === DataViewFormat.Grid}
        />
        <label htmlFor="rbGridView" className="grid">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          {t('GRID')}
        </label>
      </span>
      <span className="card-view-selector selector">
        <input
          id="rbCardView"
          name="view-selector"
          type="radio"
          className="visually-hidden"
          onChange={onChange}
          value={DataViewFormat.Card}
          checked={viewFormat === DataViewFormat.Card}
        />
        <label htmlFor="rbCardView" className="card">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M4 18h17v-6H4v6zM4 5v6h17V5H4z" /><path d="M0 0h24v24H0z" fill="none" />
          </svg>
          {t('CARDS')}
        </label>
      </span>
    </fieldset>
  );
};
