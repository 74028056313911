import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isValidEmail, isValidPhone, isValidName } from '../utils';

const SearchType = Object.freeze({
  Email: 1,
  Name: 2,
  Phone: 3,
  Status: 4,
});

const SearchByDisplayValue = Object.freeze({
  EMAIL: 'Email',
  NAME: 'Name',
  PHONE_NUMBER: 'Phone number',
  STATUS: 'Status',
});

const FilterValueFieldName = Object.freeze({
  Email: 'Email',
  Name: 'Name',
  Phone: 'Phone',
  Status: 'Status',
});

export interface SearchState {
  searchCriteria: { searchType: MembershipSearchType; searchText: string } | null;
  filterOptions: MembershipFilterOption[];
  pageNumber: number;
  itemsPerPage: number;
  response: SearchMembershipResponse | null;
  isLoading: boolean;
  hasError: boolean;
}

const initialState: SearchState = {
  searchCriteria: null,
  filterOptions: [
    // Initialize with your filter options here
    {
      displayValue: SearchByDisplayValue.EMAIL,
      searchType: SearchType.Email,
      isSelected: true,
      value: '',
      placeholder: 'example@mail.com',
      name: FilterValueFieldName.Email,
      validator: isValidEmail,
      errorMessage: '',
    },
    {
      displayValue: SearchByDisplayValue.NAME,
      searchType: SearchType.Name,
      isSelected: false,
      value: '',
      placeholder: 'Enter first or last name.',
      name: FilterValueFieldName.Name,
      validator: isValidName,
      errorMessage: '',
    },
    {
      displayValue: SearchByDisplayValue.PHONE_NUMBER,
      searchType: SearchType.Phone,
      isSelected: false,
      value: '',
      name: FilterValueFieldName.Phone,
      validator: isValidPhone,
      errorMessage: '',
    },
    {
      displayValue: SearchByDisplayValue.STATUS,
      searchType: SearchType.Status,
      isSelected: false,
      value: 'Active',
      name: FilterValueFieldName.Status,
      options: [
        { key: 'Active', displayValue: 'Active' },
        { key: 'Disabled', displayValue: 'Suspended' },
        { key: 'Pending', displayValue: 'Invitation Sent' },
      ],
    },
  ],
  pageNumber: 1,
  itemsPerPage: 10,
  response: null,
  isLoading: false,
  hasError: false,
};

const searchStateSlice = createSlice({
  name: 'searchState',
  initialState,
  reducers: {
    setSearchCriteria(state, action: PayloadAction<{ searchType: MembershipSearchType; searchText: string } | null>) {
      state.searchCriteria = action.payload;
      state.pageNumber = 1; // Reset to first page
    },
    updateFilterOptions(state, action: PayloadAction<MembershipFilterOption[]>) {
      state.filterOptions = action.payload;
    },
    setPageNumber(state, action: PayloadAction<number>) {
      state.pageNumber = action.payload;
    },
    setItemsPerPage(state, action: PayloadAction<number>) {
      state.itemsPerPage = action.payload;
    },
    setResponse(state, action: PayloadAction<SearchMembershipResponse>) {
      state.response = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state, action: PayloadAction<boolean>) {
      state.hasError = action.payload;
    },
  },
});

export const { setSearchCriteria, setPageNumber, setItemsPerPage, setResponse, setLoading, setError, updateFilterOptions } = searchStateSlice.actions;

export default searchStateSlice.reducer;
