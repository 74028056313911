import { Trans, useTranslation } from 'react-i18next';

interface LoadCommodityProps {
  commodities: AvailableLoadItemJSON[];
}

export const LoadCommodity = ({ commodities }: LoadCommodityProps) => {
  const { t } = useTranslation();
  const firstComponentName = commodities[0]?.commodityName;
  return (
    <div className="commodity-info">
      <div className="commodity-name">
        {firstComponentName ? firstComponentName : t('NO_COMMODITY_INFO_AVAILABLE')}
      </div>
      {commodities.length > 1 && (
        <div className="multiple-commodities">
          <Trans i18nKey="PLUS_NUMBER_MORE" components={[<span> {commodities.length - 1}</span>]} />
        </div>
      )}
    </div>
  )
};
