import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { useTranslation, Trans } from 'react-i18next';

import { history } from 'store';
import { EmailVerificationIcon } from '@features/okta/components/RegistrationEmailVerification/EmailVerificationIcon';
import { AppRoute } from '@app/routesEnum';

import {
  ContainerStyles,
  HeaderContainerStyles,
  ActionBtnStyles,
  SubHeaderContainerStyles,
} from 'features/registration/styles/registration-shared-styles';

type Props = {
  email: string;
};

export const VerificationSent = ({ email }: Props) => {
  const handleClose = () => history.push('/');
  const { t } = useTranslation();

  return (
    <Box sx={ContainerStyles}>
      <Box sx={HeaderContainerStyles}>
        <EmailVerificationIcon altText={t("OKTA_EMAIL_VERIFICATION_ICON_ALT_TEXT")} />
      </Box>
      <Box sx={HeaderContainerStyles}>
        <Typography variant="h1">
          {t("VERIFICATION_EMAIL_SENT")}
        </Typography>
      </Box>
      <Box sx={SubHeaderContainerStyles}>
        <Typography variant="h4">
          {t('CLICK_THE_LINK', { 0: email })}
        </Typography>
        <Link href={AppRoute.OKTA_FAQ}>
          {t("LEARN_MORE")}
        </Link>
      </Box>
      <Button
        onClick={handleClose}
        variant="contained"
        sx={ActionBtnStyles}
      >
        {t("CLOSE")}
      </Button>
      <Box sx={SubHeaderContainerStyles}>
        <Typography sx={{ paddingTop: '48px', fontSize: '14px' }}>
          <Trans
            i18nKey="DID_NOT_GET_THE_EMAIL"
            components={[<a href="mailto:navispherecarrier@chrobinson.com">navispherecarrier@chrobinson.com</a>]}
          />
        </Typography>
      </Box>
    </Box>
  )
}
