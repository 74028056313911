import { Headers, ErrorCodes } from "app/globals/constants";
import Result from "@lib/result";
import { store } from 'store';
import { logout } from "@features/security/auth.actions";
import { environment } from "environments/environment";
import { Http } from "app/globals/constants";
import { OktaAuth, OktaAuthIdxOptions } from '@okta/okta-auth-js';
import { getOktaConfig } from '@features/okta/config';

const oktaAuth = new OktaAuth(getOktaConfig() as OktaAuthIdxOptions);

const getToken = async (): Promise<result<string>> => {
  const accessToken = await oktaAuth.getOrRenewAccessToken();
  if (accessToken) {
    return Result.Success<string>(`Bearer ${accessToken}`);
  }
  return Result.Fail({ message: "Token not found", code: ErrorCodes.AUTH_TOKEN_NOT_FOUND });
}

const handleSuccessfulResponseAsync = async <T>(response: Response): Promise<result<T>> => {
  if (!response) {
    return Result.Success<T>(null);
  }

  const contentType = response.headers.get("content-type");

  if (!contentType) {
    return Result.Success<T>(null);
  }

  if (contentType.includes("application/json")) {
    return Result.Success<T>(await response.json() as T);
  }

  if (contentType.includes("text")) {
    return Result.Success<T>(await response.json() as T);
  }
}

const handleFailureAsync = async (response: Response) => {
  try {
    if (response.status === 401) {
      store.dispatch(logout());
    }
    const hasStatusText = !!response.statusText;
    const errorMessage = hasStatusText
      ? response.statusText
      : await response.text();
    return Result.Fail({ code: response.status, message: errorMessage });
  } catch (error) {
    return Result.Fail({ code: response.status, message: `${response.statusText}` });
  }
}

const baseFetch = async (url: string, info: RequestInit): Promise<result<Response>> => {
  try {
    const token = await getToken();
    if (info.headers === undefined) {
      info.headers = {};
    }

    if (token.success) {
      info.headers[Headers.AUTHORIZATION] = token.value;
    }

    info.headers[Headers.API_KEY] = environment.apiKey;

    const response = await fetch(url, info);
    if (!response.ok) {
      return await handleFailureAsync(response);
    }

    return Result.Success(response);
  } catch (error) {
    return Result.Fail(error);
  }
}

export const buildUrl = (baseUrl: string, path: string): string => {
  const cleansedBaseUrl = baseUrl.replace(/\/$/, '');
  const cleansedPath = path.replace(/^\//, '');
  return `${cleansedBaseUrl}/${cleansedPath}`;
}

export async function get<T>(url: string, customHeaders: any = {}): Promise<result<T>> {
  const responseResult = await baseFetch(url, {
    method: Http.GET,
    headers: customHeaders
  });

  return responseResult.success
    ? await handleSuccessfulResponseAsync<T>(responseResult.value)
    : Result.Fail(responseResult.error);
}

export async function post<T>(url: string, customHeaders: any = {}, payload: any = {}): Promise<result<T>> {

  const defaultHeaders = {
    'Content-Type': 'application/json'
  };

  const responseResult = await baseFetch(url, {
    method: Http.POST,
    headers: { ...defaultHeaders, ...customHeaders },
    body: JSON.stringify(payload)
  });

  return responseResult.success
    ? await handleSuccessfulResponseAsync<T>(responseResult.value)
    : Result.Fail(responseResult.error);
}


export function getClaims(): result<userClaims> {
  const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage')) ?? {};
  if (accessToken) {
    const response = {
      carrierCode: accessToken.claims?.carrierCode,
      userId: accessToken.claims?.carrierId,
      username: accessToken.claims?.carrierUsername,
      email: accessToken.claims?.sub,
    }
    return Result.Success<userClaims>(response);
  }
  return Result.Fail({ message: "Token not found", code: ErrorCodes.AUTH_TOKEN_NOT_FOUND });
}

export default {
  getClaims,
  buildUrl,
  get,
  post
}