import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { FilterTextField, FilterPhoneField, FilterStatusField } from './FilterFormFields';
import { setSearchCriteria, setLoading, setError, updateFilterOptions } from '../redux';
import * as Styles from 'features/account-settings/manage-users/styles';

interface FilterFormProps {
  isLoading: boolean;
}

export const FilterValueFieldName = Object.freeze({
  Email: 'Email',
  Name: 'Name',
  Phone: 'Phone',
  Status: 'Status',
});

export const SearchByDisplayValue = Object.freeze({
  EMAIL: 'Email',
  NAME: 'Name',
  PHONE_NUMBER: 'Phone number',
  STATUS: 'Status',
});

export const FilterForm: React.FC<FilterFormProps> = ({ isLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterOptions = useSelector((state: any) => state.searchState?.filterOptions) ?? [];
  const selectedOption = filterOptions.find(option => option.isSelected);

  const updateOptions = (options: any[]) => dispatch(updateFilterOptions(options));

  const handleSearchByChange = useCallback(
    (e: any) => {
      updateOptions(filterOptions.map(option => ({ ...option, isSelected: option.displayValue === e.target.value, value: '' })));
    },
    [filterOptions]
  );

  const handleSearch = useCallback(() => {
    if (selectedOption) {
      dispatch(setLoading(true));
      try {
        if (selectedOption.name === FilterValueFieldName.Status) {
          if (!selectedOption.options.map(opt => opt.key).includes(selectedOption.value)) {
            updateOptions(
              filterOptions.map(c => (c.isSelected ? { ...c, errorMessage: t('Invalid status selection') } : { ...c, value: '', errorMessage: '' }))
            );
            return;
          }
        } else if (selectedOption.validator) {
          const { valid, message: errorMessage } = selectedOption.validator(selectedOption.value);
          if (!valid) {
            updateOptions(filterOptions.map(c => (c.isSelected ? { ...c, errorMessage: t(errorMessage) } : { ...c, value: '', errorMessage: '' })));
            return;
          }
        }
        dispatch(setSearchCriteria({ searchType: selectedOption.searchType, searchText: selectedOption.value.trim() }));
      } catch (error) {
        dispatch(setError(true));
      } finally {
        dispatch(setLoading(false));
      }
    }
  }, [filterOptions, dispatch, t]);

  const handleOnChange = useCallback(
    (value: string) => {
      updateOptions(filterOptions.map(option => (option.isSelected ? { ...option, value, errorMessage: '' } : option)));
    },
    [filterOptions]
  );

  const handleOnClear = useCallback(() => {
    const clearedOptions = filterOptions.map(option => ({
      ...option,
      isSelected: option.name === SearchByDisplayValue.EMAIL,
      value: '',
      errorMessage: '',
    }));
    updateOptions(clearedOptions);
    dispatch(setSearchCriteria(null));
  }, [filterOptions]);

  return (
    <Box sx={Styles.Form}>
      <Box sx={Styles.SearchBy}>
        <Typography variant="h4" data-testid="title">
          {t('SEARCH_BY')}
        </Typography>
      </Box>
      <Box sx={Styles.FiltersWrapper}>
        <Select data-testid="search-by" fullWidth value={selectedOption?.displayValue || ''} onChange={handleSearchByChange} sx={Styles.FilterSelect}>
          {filterOptions.map(c => (
            <MenuItem key={c.displayValue} value={c.displayValue} data-testid={c.displayValue}>
              <Typography variant="h4">{c.displayValue}</Typography>
            </MenuItem>
          ))}
        </Select>

        {selectedOption && (
          <>
            {selectedOption.name === FilterValueFieldName.Name || selectedOption.name === FilterValueFieldName.Email ? (
              <FilterTextField
                data-testid="email-input"
                value={selectedOption.value}
                placeholder={selectedOption.placeholder}
                onChange={handleOnChange}
                errorMessage={selectedOption.errorMessage}
              />
            ) : selectedOption.name === FilterValueFieldName.Phone ? (
              <FilterPhoneField value={selectedOption.value} onChange={handleOnChange} errorMessage={selectedOption.errorMessage} />
            ) : selectedOption.name === FilterValueFieldName.Status ? (
              <FilterStatusField value={selectedOption.value} options={selectedOption.options} onChange={handleOnChange} />
            ) : null}
          </>
        )}

        <Button data-testid="search-button" disabled={isLoading} variant="contained" color="primary" onClick={handleSearch} sx={Styles.SearchButton}>
          {t('SEARCH')}
        </Button>
        <Button
          data-testid="clear-button"
          variant="outlined"
          color="primary"
          onClick={handleOnClear}
          sx={Styles.ClearButton}
        >
          {t('CLEAR')}
        </Button>
      </Box>
    </Box>
  );
};
