import { useTranslation } from 'react-i18next';
import './system-disruption.component.scss';

export const SystemDisruption = (props: { pageNameResourceKey?: ResourceKey, disruptionType: string }) => {
    const { t } = useTranslation();
    if (props.pageNameResourceKey != null) {
        return (
            <div className='text-center space-outer-top-xxl' data-testid='system-disruption'>
                <img src='/assets/images/construction-barrier.svg' alt='Construction Barrier Icon' />
                <h1 className='space-outer-top-lg'>{t(props.pageNameResourceKey)}{" "}{t('IS_TEMPORARILY_UNAVAILABLE')}</h1>
                <div id="system-disruption">
                    <p>{t('WORKING_TO_FIX')}
                        <br />{props.disruptionType === "PAGE_LEVEL_DISRUPTION" ? t('CHECK_BACK_IN_THE_MEANTIME') : t('APOLOGIZE_FOR_INCONVENIENCE')}</p>
                </div>
            </div>
        );
    }
}