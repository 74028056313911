import { ReloadEventData } from 'features/analytics/redux/analytics-v2.actions';
import { ReloadMetadataContext } from 'shared/reloads/analytics/reload-metadata.context';
import { useEffect, useMemo } from 'react';
import { useAvailableLoadDetails } from 'shared/find-loads/hooks/use-available-load-detail.hook';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { LoadingIndicatorSpinner } from 'shared/components/loading/inline-loaders/loading-indicator-spinner.component';
import { LoaderNames, useLoader } from 'app/hooks/use-loader.hook';
import { useReloads } from 'pages/find-loads-ver2/hooks/use-reloads.hook';
import { useLoadsSearchState } from 'pages/find-loads-ver2/hooks/use-loads-search-state.hook';
import { ReloadsType } from 'shared/enums/reloads-type.enum';
import { AvailableLoadComponent } from 'pages/find-loads-ver2/details/available-load.component';
import { NoAvailableLoadComponent } from 'pages/find-loads-ver2/details/no-available-load.component';
import { useLoadDetailAnalytics } from 'features/analytics/hooks/use-load-detail-analytics.hook';
import { useCarrierValidation } from 'pages/find-loads-ver2/hooks/use-carrier-validation.hook';
import './load-detail.component.scss';

interface Props {
  load: AvailableLoadSummary;
  onClose?: () => void;
  setLoadDetailRef?: (ref: HTMLElement) => void;
  focusSelectedLoadResult?: () => void;
  sendLDEvents: boolean;
  position?: number
}

const LoadDetailContainerClassName = 'find-loads-detail';

export const LoadDetail = ({ load, onClose, focusSelectedLoadResult, setLoadDetailRef, sendLDEvents, position }: Props) => {
  const numberOfReloadsToShow = 3;
  const [loadDetails, loadError] = useAvailableLoadDetails(load?.number, LoaderNames.FindLoadsVer2LoadDetails);
  useCarrierValidation(load);
  const loadsSearchState = useLoadsSearchState();
  const reloadsData = useReloads(load, loadsSearchState, ReloadsType.PreBookReloads, LoaderNames.FindLoadsVer2LoadDetailReloads, numberOfReloadsToShow);
  const reloadData = useMemo<ReloadEventData>(
    () => {
      if (!loadsSearchState?.searchCriteria?.reloadsCriteria) {
        return null;
      }

      return {
        primalLoadNumber: loadsSearchState.searchCriteria.reloadsCriteria.primalLoadDetails.primalLoadNumber,
        callVariation: loadsSearchState.searchCriteria.reloadsCriteria.callVariation,
        reloadNumber: load?.number,
      };
    },
    [loadsSearchState]
  );
  const loader = useLoader(LoaderNames.FindLoadsVer2LoadDetails);
  const trackLoadDetailEvent = useLoadDetailAnalytics();

  const showError = (!loadDetails || loadError) && !loader.visible;
  const showLoadDetail = (loadDetails && !showError);

  useEffect(() => {
    if (showLoadDetail && load) {
      trackLoadDetailEvent(load, position);
    }
  }, [showLoadDetail]);

  if (!load) {
    return <div className={LoadDetailContainerClassName} data-testid="no-load" />;
  }

  return (
    <div ref={setLoadDetailRef} tabIndex={0} className={`${LoadDetailContainerClassName} area-border`} data-testid="load-detail-container">
      <LoadingIndicatorSpinner mainText="LOADING___" isVisible={loader.visible} />
      {showError &&
        <NoAvailableLoadComponent
          loadNumber={load.number}
          loadError={loadError}
          handleOnClose={onClose}
          handleFocusSelectedLoadResult={focusSelectedLoadResult}
        />
      }
      {showLoadDetail &&
        <ReloadMetadataContext.Provider value={{ reloadData: reloadData }}>
          <AvailableLoadComponent
            load={load}
            reloadsData={reloadsData}
            loadDetails={loadDetails}
            handleOnClose={onClose}
            handleFocusSelectedLoadResult={focusSelectedLoadResult}
            sendLDEvents={sendLDEvents}
            position={position}
          />
        </ReloadMetadataContext.Provider>
      }
    </div>
  );
};

export default LoadDetail;
