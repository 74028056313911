import moment from 'moment';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { ExtendedStop } from 'shared/models/loads/stops/extended-stop.model';
import { BaseModel } from 'shared/models/base.model';
import { ExtendedLoadBook } from 'shared/models/loads/load-books/extended-load-book.model';
import { LoadStatus } from 'shared/enums/load-status.enum';
import { ExtendedCommodity } from 'shared/models/loads/commodities/extended-commodity.model';
import { LoadProblem } from 'shared/models/loads/load-problems/load-problem.model';
import { AssignableLoad, Cloneable } from 'shared/components/assign-driver/assign-driver.component';
import { Driver } from 'shared/models/contacts/driver.model';
import { Util } from 'app/util/util';
import { LoadType } from 'shared/enums/load-type.enum';
import { BookType, LTLBookTypes } from 'shared/enums/book-type.enum';
import { StopType } from 'shared/enums/stop-type.enum';
import { Financials } from 'shared/models/my-loads/financials/financials.model';
import { CapabilityPermission } from 'shared/enums/capability-permission.enum';
import { AccessorialStop } from 'shared/models/my-loads/financials/accessorial-stop.model';
import { Address } from 'shared/models/address/address.model';
import { FinancialsPermission } from 'shared/enums/financials/financials-permissions.enum';
import { TrackingMessageType } from 'shared/enums/tracking-message-type.enum';
import { outsideOfAppMessageCodes, insideOfAppMessageCodes } from 'pages/my-loads/constants';

export class ExtendedLoad extends BaseModel implements AssignableLoad, Cloneable, ExtendedLoadInterface {
  status: LoadStatusJSON;
  activityDate: Date; // a.k.a Available Pickup Date
  deliverByDate: Date;
  billingAddress: Address;
  isEuropean: boolean;
  isHazMat: boolean;
  isHazMatPlacarded: boolean;
  isLogistics: boolean;
  isTMCLoad: boolean;
  isReleasedForPayment: boolean;
  paoLoad: boolean;
  finLocked: boolean;
  isRegulatedBySTF: boolean;
  isTankerEndorsementRequired: boolean;
  canAddTracking: boolean;
  isBlindShipment: boolean;
  isSealRequired: boolean;
  distance: number;

  carrierID: string;

  referenceNumbers: ReferenceNumberJSON[];
  stops: ExtendedStop[];

  containerNumber: string;
  loadType: number;
  requiredEquipment?: {
    height?: number;
    width?: number;
    length?: number;
    cetEquip: string;
    equipmentDescription: string;
    requiredType: string;
    requiredTypeDescription: string;
    specializeEquipType: string;
  };
  capabilities: { [key: string]: CapabilityPermission };
  financialsPermissions: FinancialsPermission[];
  trackingMessageCode: TrackingMessageType;
  private financialsInformation: Financials;
  private loadNumber: number;
  private totalWeight: number;
  private totalPieces: number;
  private billTos: BillToJSON[];
  public firstBillTo: BillToJSON;
  private teamRequirement: boolean;
  private activeBooking: ExtendedLoadBook;
  public loadProblems: LoadProblem[];
  private items: ExtendedCommodity[];
  private finalUpdateDeadline: Date;
  private firstPick: ExtendedStop;
  private lastDrop: ExtendedStop;

  get financials(): Financials {
    return this.financialsInformation;
  }
  set financials(financials: Financials) {
    this.financialsInformation = financials;
  }
  get salesRep(): typeof ExtendedLoadBook.prototype.salesRep {
    return this.loadBook?.salesRep || {} as any;
  }

  /**
   * Returns the value of the first reference number with the "GID" code.
   * @returns {string}
   */
  get groupId() {
    if (Array.isArray(this.referenceNumbers)) {
      const codes = this.referenceNumbers.filter(num => num.code === 'GID').map(num => num.value);
      if (codes.length) {
        return codes[0];
      }
    }
  }

  set commodities(commodities: ExtendedCommodity[]) {
    this.items = commodities;
  }
  get commodities(): ExtendedCommodity[] {
    return this.items;
  }

  get loadBook(): ExtendedLoadBook {
    return this.activeBooking;
  }
  set loadBook(book: ExtendedLoadBook) {
    this.activeBooking = book;
  }
  set proNumber(proNumber: string) {
    this.activeBooking.proNumber = proNumber;
  }

  get bookSequenceNumber() {
    return this.loadBook.sequenceNumber;
  }

  get isTeamRequired(): boolean {
    return this.teamRequirement;
  }
  set isTeamRequired(value: boolean) {
    this.teamRequirement = value;
  }

  get number() {
    return this.loadNumber;
  }
  get partialNumber() {
    const loadNumberString = this.number.toString();
    let response = '';
    for (let i = 0; i < loadNumberString.length; i++) {
      response += (i >= loadNumberString.length - 4) ? loadNumberString[i] : '*';
    }
    return response;
  }
  set number(value: number) {
    this.loadNumber = value;
  }

  get itemTotalWeight() {
    return this.totalWeight;
  }
  set itemTotalWeight(value: number) {
    this.totalWeight = value;
  }

  get itemTotalPieces() {
    return this.totalPieces;
  }
  set itemTotalPieces(value: number) {
    this.totalPieces = value;
  }

  get firstBillRecipient() {
    return BaseModel.setValues({}, this.firstBillTo);
  }

  get billRecipients() {
    return this.billTos.map(recipient => BaseModel.setValues({}, recipient))
      .sort(Util.sortByField('sequenceNumber'));
  }

  set problems(problems: LoadProblem[]) {
    this.loadProblems = problems;
  }

  get problems(): LoadProblem[] {
    return this.loadProblems;
  }

  get totalExtendedRateAmount(): number {
    return this.financials?.totalExtendedRateAmount || 0;
  }

  get currencyCode(): string {
    return this.financials?.currencyCode || '';
  }

  constructor(json: ExtendedLoadJSON) {
    super(json);

    // dates
    this.activityDate = this.processDate(json.activityDate);
    this.deliverByDate = this.processDate(json.deliverByDate);

    this.problems = [];
    this.commodities = [];
    this.stops = [];

    this.loadBook = new ExtendedLoadBook(json.activeBooking);
    this.loadBook.loadNumber = this.number;

    this.itemTotalWeight = this.totalWeight;
    this.itemTotalPieces = this.totalPieces;

    if (json.loadProblems && Array.isArray(json.loadProblems)) {
      this.problems = json.loadProblems.map(problem => new LoadProblem(problem));
      this.problems.sort(Util.sortByField('problemSequenceNumber'));
      this.problems.sort(Util.sortByField('stopSequenceNumber'));
    }
    if (json.stops && Array.isArray(json.stops)) {
      this.stops = json.stops.map(stop => new ExtendedStop(stop));
      this.stops.sort((a, b) => a.number - b.number);
    }
    if (json.items && Array.isArray(json.items)) {
      this.commodities = json.items.map(item => new ExtendedCommodity(item));
    }
    if (json?.financialsInformation) {
      this.financials = new Financials(json.financialsInformation);
    }
    if (json?.financialsPermissions) {
      this.financialsPermissions = json.financialsPermissions;
    }
  }

  clone() {
    return new ExtendedLoad(this.toJson());
  }

  toJson(): ExtendedLoadJSON {
    const baseJSON: any = Object.assign({}, this);

    delete baseJSON.finalUpdateDeadline;
    delete baseJSON.firstPick;
    delete baseJSON.lastDrop;

    return Object.assign(baseJSON, {
      activeBooking: this.activeBooking.toJson(),
      loadProblems: this.loadProblems.map(problem => problem.toJson()),
      stops: this.stops.map(stop => stop.toJson()),
      items: this.items.map(item => item.toJson()),
      activityDate: this.convertDateToDateString(this.activityDate),
      deliverByDate: this.convertDateToDateString(this.deliverByDate),
      financialsInformation: this.financials?.toJson()
    });

  }

  isDelivered(): boolean {
    return this.status.code === LoadStatus.Delivered || this.status.code === LoadStatus.Completed;
  }

  isTendered(): boolean {
    return this.status.code === LoadStatus.Tendered;
  }

  isInternational(): boolean {
    return this.loadType === LoadType.International;
  }

  isIntermodalDelivery() {
    return this.loadBook.bookType === BookType.IntermodalDelivery;
  }

  isIntermodal(): boolean {
    return this.loadType === LoadType.Intermodal;
  }

  assignDriver(driver: Driver) {
    this.loadBook.driver1FirstName = driver.firstName;
    this.loadBook.driver1LastName = driver.lastName;
    this.loadBook.driverCellPhone = driver.phoneNumber;
  }

  getDriverFullName(): string {
    if (!this.loadBook.driver1FirstName && !this.loadBook.driver1LastName) {
      return;
    }
    return ((this.loadBook.driver1FirstName || '') + ' ' + (this.loadBook.driver1LastName || '')).trim();
  }

  hasDriver(): string {
    return this.loadBook.driver1FirstName || this.loadBook.driver1LastName;
  }

  getTotalValue() {
    if (Array.isArray(this.commodities)) {
      return this.commodities.reduce((sum, commodity) => (sum + commodity.value), 0);
    }
    return 0;
  }
  getTotalWeight() {
    if (Array.isArray(this.commodities)) {
      return this.commodities.reduce((sum, commodity) => (sum + commodity.expectedMaxWeight), 0);
    }
    return 0;
  }
  get measurements() {
    const carrierEquip = this.loadBook?.carrierEquipment;
    return {
      length: (carrierEquip?.length) || (this.requiredEquipment?.length),
      width: (carrierEquip?.width) || (this.requiredEquipment?.width),
      height: (carrierEquip?.height) || (this.requiredEquipment?.height),
    };
  }
  getFinalStop(): ExtendedStop {
    return this.stops.slice().sort((a, b) => a.number - b.number).pop();
  }

  getFinalUpdateDeadline(): Date {
    if (!this.finalUpdateDeadline) {
      // cache the result.
      const finalStop = this.getFinalStop();
      if (!finalStop.completed) {
        return;
      }
      const deliveryDate = moment(finalStop.completed);
      const updateDeadline = moment(finalStop.completed).add(this.isLogistics ? 72 : 24, 'h');

      if (deliveryDate.day() >= 5 || deliveryDate.day() === 0 || updateDeadline.day() >= 5) {
        // loads delivered Friday through Sunday get an additional 48 hours.
        updateDeadline.add(48, 'h');
      }
      this.finalUpdateDeadline = updateDeadline.toDate();
    }

    return this.finalUpdateDeadline;
  }

  financialsDeadlineHasPassed(): boolean {
    return this.financials
      ? this.financials.deadlineHasPassed()
      : this.isPastFinalUpdateDeadline();
  }

  isPastFinalUpdateDeadline() {
    if (!this.getFinalUpdateDeadline()) {
      return false;
    }
    return new Date() >= this.getFinalUpdateDeadline();
  }

  getNextStop(): ExtendedStop {
    return this.stops.slice().sort((a, b) => a.number - b.number).find(stop => !stop.completed);
  }

  getFirstPick(): ExtendedStop {
    if (!this.firstPick) {
      const sortedStops = this.stops.slice().sort((a, b) => a.number - b.number);
      this.firstPick = sortedStops.find(stop => stop.stopType === StopType.PICKUP);
    }
    return this.firstPick;
  }

  getPreviousStop(stop: ExtendedStop): ExtendedStop {
    const sortedLoads = this.stops.slice().sort((a, b) => a.number - b.number);
    if (sortedLoads.length >= 2) {
      const thisStopIndex = sortedLoads.findIndex(stopToCheck => stopToCheck.sequenceNumber === stop.sequenceNumber);
      return sortedLoads[thisStopIndex - 1];
    } else {
      return this.stops[0];
    }
  }

  getLastDrop(): ExtendedStop {
    if (!this.lastDrop) {
      const sortedStops = this.stops.slice().sort((a, b) => a.number - b.number);
      this.lastDrop = sortedStops.reverse().find(stop => stop.stopType === StopType.DROP_OFF);
    }
    return this.lastDrop;
  }

  isRefrigerated() {
    return this.requiredEquipment?.requiredType && (this.requiredEquipment.requiredType.indexOf('R') > -1);
  }

  getStopBySequenceNumber(sequenceNumber: number) {
    return this.stops.find(stop => stop.sequenceNumber === sequenceNumber);
  }

  getAccessorialStopBySequenceNumber(sequenceNumber: number): AccessorialStop {
    return this.financials?.eligibleAccessorialStops.find(stop => stop.sequenceNumber === sequenceNumber);
  }

  get minimumPayload(): number {
    return this.stops?.reduce((payload, stop) => payload + stop.weight, 0);
  }
  hasFinancialPermissions(permission: FinancialsPermission) {
    return Boolean(this.financialsPermissions?.includes(permission));
  }
  isLTLBookType(): boolean {
    return LTLBookTypes.includes(this.loadBook.bookType);
  }

  isTrackingActionNeeded(): boolean {
    return outsideOfAppMessageCodes.indexOf(this.trackingMessageCode) !== -1 || insideOfAppMessageCodes.indexOf(this.trackingMessageCode) !== -1;
  }
}

export class LoadNotFound extends ExtendedLoad {
  public constructor(loadNumber: number) {
    super({ loadNumber } as any);
  }
}

export class LoadError extends ExtendedLoad {
  public constructor(public error: AjaxError, loadNumber: number) {
    super({ loadNumber } as any);
  }
}
