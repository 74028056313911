import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { TextInput } from '@chr/eds-react';
import { useTranslation, Trans } from 'react-i18next';
import { Anchor } from 'shared/components/elements/anchor.component';
import { CustomerSupportEmail } from 'shared/components/customer-support/customer-support-email.component';
import {
	RegistrationPhoneNumberInput,
	EdsStyleErrorMessage,
	CarrierCodePositionValues,
	PositionRadioGroup,
	useRegistrationForm,
	FormFields,
	PhoneNumberType,
	CarrierCodeType,
	RegistrationState,
} from 'features/registration';

import * as Styles from 'features/registration/styles/form-styles';
import { useCallback, useEffect, useState } from 'react';
import { TurnstileCaptchaComponent } from '@shared/components/turnstile-captcha/turnstile-captcha.component';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CaptchaStorageKeys } from '@shared/components/turnstile-captcha/types';

export interface RegistrationFormProps {
	email: string;
	otp: string;
	setRegistrationState: (state: RegistrationState) => void;
}

export const RegistrationFormComponent = ({ email, otp, setRegistrationState }: RegistrationFormProps) => {
	const { t } = useTranslation();
	const [resetTracker, setResetTracker] = useState({ value: 0, reset: false });
	const { isIdentityUserCaptchaTokenEnabled } = useFlags();
	const isEphemeral = window.location.host.includes('.chr8s.io');
	const isEnabled = !isEphemeral && isIdentityUserCaptchaTokenEnabled;
	const [isLoading, setIsLoading] = useState(false);
	const [wasCarrierCodeValidationPerformed, setWasCarrierCodeValidationPerformed] = useState(false);
	const [flagCountryCode, setFlagCountryCode] = useState(null);

	const {
		values,
		errors,
		handleFieldChange,
		handleOnBlur,
		handleSubmission,
		isCarrierCodeFocused
	} = useRegistrationForm({
		otp: otp,
		phoneNumberType: PhoneNumberType.MOBILE,
		promotionalConsent: false,
		carrierCodeType: CarrierCodeType.T_CODE,
		carrierCode: '',
		companyName: '',
		position: '',
		firstName: '',
		lastName: '',
		phoneNumber: '',
	}, setRegistrationState, setWasCarrierCodeValidationPerformed, flagCountryCode);

	const isTCode = values.carrierCodeType === CarrierCodeType.T_CODE;
	const isVCode = values.carrierCodeType === CarrierCodeType.V_CODE;
	const isDriver = values.position === CarrierCodePositionValues.DRIVER_LCASE;

	const carrierCodeHelperText = isTCode ? t('EXAMPLE_T123456') : t('EXAMPLE_V123456');

	const formHasErrors = Object.values(errors).some((value) => value.trim());

	const handleReset = useCallback(() => {
	  setResetTracker((s) => s.value > 0 ? ({ ...s, reset: true }) : s);
	}, []);

	useEffect(() => {
		if (wasCarrierCodeValidationPerformed && isEnabled) {
		  setWasCarrierCodeValidationPerformed(false);
		  setIsLoading(true);
		  setResetTracker((s) => ({ ...s, value: Date.now() }));
		}
	}, [wasCarrierCodeValidationPerformed])

	useEffect(() => {
	  if (!isEnabled || (resetTracker.reset)) {
		setIsLoading(false);
		isEnabled && setResetTracker((s) => ({ ...s, reset: false }));
	  }
	}, [isEnabled, resetTracker.reset])

	const getCarrierCodeInputHelperText = () => {
	  if (isLoading) {
		if (values.carrierCodeType === CarrierCodeType.T_CODE) return t('VERIFYING_CARRIER_CODE');
		if (values.carrierCodeType === CarrierCodeType.V_CODE) return t('VERIFYING_VENDOR_CODE');
	  }
	  else if (errors.carrierCode) return errors.carrierCode;
	  else if (!isCarrierCodeFocused && values.carrierCode) {
		if (values.carrierCodeType === CarrierCodeType.T_CODE) return t('VERIFIED_CARRIER_CODE');
		if (values.carrierCodeType === CarrierCodeType.V_CODE) return t('VERIFIED_VENDOR_CODE');
	  }
	  else return carrierCodeHelperText;
	}

	return (
		<Box sx={Styles.RegistrationFormContainer}>
			<Box sx={Styles.Heading}>
				<Typography variant="h1">{t('CREATE_A_NAVISPHERE_CARRIER_ACCOUNT')}</Typography>
			</Box>
			<Box sx={Styles.Subtitle}>
				<Typography variant="subtitle1">
					<Trans
						i18nKey="TO_CREATE_A_NAVISPHERE_CARRIER_ACCOUNT_YOU_NEED_0_1"
						components={[
							<Anchor
								resource="REGISTER_TO_BECOME_A_CONTRACT_CARRIER"
								href="https://chrcarriers.rmissecure.com/_s/reg/generalrequirementsv2.aspx"
							/>,
							<Anchor resource="LEARN_MORE_ABOUT_CH_ROBINSON" href="https://www.chrobinson.com/en-us/carriers/" target="_blank" />,
						]}
					/>
				</Typography>
			</Box>
			<Box sx={Styles.Subtitle}>
				<Typography variant="subtitle1">{t('ALL_FIELDS_ARE_REQUIRED')}</Typography>
			</Box>
			<Box sx={Styles.FormContainer}>
				<Typography variant="h3">{t('GENERAL_INFORMATION')}</Typography>
				<Grid container spacing={2} sx={Styles.InputGrid}>
					<Grid item sm={6} xs={12}>
						<TextInput label={t('VERIFIED_EMAIL')} value={email} disabled onChange={() => { }} endAdornment="check_circle" fullWidth />
					</Grid>
				</Grid>
				<Box>
					<Typography variant="subtitle2">{t('POSITION')}</Typography>
					<Typography variant="body1">{t('POSITION_PARAGRAPH_LINE_1')}</Typography>
					<Typography variant="body1">{t('POSITION_PARAGRAPH_LINE_2')}</Typography>
				</Box>
				<PositionRadioGroup
					value={values.position}
					handleChange={handleFieldChange(FormFields.POSITION)}
					error={errors.position}
					isTCode={isTCode}
				/>
				{!isDriver && (
					<Box sx={Styles.AccountInfoContainer}>
						<Typography variant="h3">{t('COMPANY_INFORMATION')}</Typography>
						<Grid container spacing={2} sx={Styles.InputGrid}>
							<Grid item sm={6} xs={12}>
								<TextInput
									label={t('COMPANY_NAME')}
									value={values.companyName}
									onChange={handleFieldChange(FormFields.COMPANY_NAME)}
									fullWidth
									onBlur={handleOnBlur(FormFields.COMPANY_NAME)}
									error={!!errors.companyName}
									helperText={errors.companyName}
								/>
							</Grid>
							<Grid item sm={6} xs={12} sx={Styles.HiddenGridItem} />
							<Grid item sm={6} xs={12}>
								<InputLabel>{t('T_CODE_OR_V_CODE')}</InputLabel>
								<ButtonGroup fullWidth>
									<Button
										sx={isTCode ? Styles.SelectedButton : Styles.FormButton}
										onClick={handleFieldChange(FormFields.CARRIER_CODE_TYPE)}
										value={CarrierCodeType.T_CODE}
									>
										{t('T_CODE')}
									</Button>
									<Button
										sx={isVCode ? Styles.SelectedButton : Styles.FormButton}
										onClick={handleFieldChange(FormFields.CARRIER_CODE_TYPE)}
										value={CarrierCodeType.V_CODE}
									>
										{t('V_CODE')}
									</Button>
								</ButtonGroup>
							</Grid>
							<Grid item sm={6} xs={12}>
								<Box sx={Styles.InputWithNoLabelPadding} />
								<TextInput
									disabled={isLoading}
									id="carrier-code-input"
									label=""
									value={values.carrierCode}
									onChange={handleFieldChange(FormFields.CARRIER_CODE)}
									startAdornment={isTCode ? 'T' : 'V'}
									helperText={getCarrierCodeInputHelperText()}
									fullWidth
									onBlur={handleOnBlur(FormFields.CARRIER_CODE)}
									error={isLoading ? false : !!errors.carrierCode}
									inputType="number"
								/>
								<Box sx={{display: 'none'}} >
								  <TurnstileCaptchaComponent
									  resetTracker={resetTracker.value}
									  isEnabled={isEnabled}
									  captchaKey={CaptchaStorageKeys.carrierCodeValidation}
									  onSuccessCallback={handleReset}
								  />
								</Box>
							</Grid>
						</Grid>
					</Box>
				)}
				<Box sx={Styles.AccountInfoContainer}>
					<Typography variant="h3">{t('ACCOUNT_INFORMATION')}</Typography>
					<Grid container spacing={2} sx={Styles.InputGrid}>
						<Grid item sm={6} xs={12}>
							<TextInput
								label={t('FIRST_NAME')}
								value={values.firstName}
								onChange={handleFieldChange(FormFields.FIRST_NAME)}
								fullWidth
								onBlur={handleOnBlur(FormFields.FIRST_NAME)}
								error={!!errors.firstName}
								helperText={errors.firstName}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextInput
								label={t('LAST_NAME')}
								value={values.lastName}
								onChange={handleFieldChange(FormFields.LAST_NAME)}
								fullWidth
								onBlur={handleOnBlur(FormFields.LAST_NAME)}
								error={!!errors.lastName}
								helperText={errors.lastName}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<InputLabel>{t('PHONE_NUMBER')}</InputLabel>
							<ButtonGroup fullWidth>
								<Button
									sx={values.phoneNumberType === PhoneNumberType.MOBILE ? Styles.SelectedButton : Styles.FormButton}
									onClick={handleFieldChange(FormFields.PHONE_NUMBER_TYPE)}
									value={PhoneNumberType.MOBILE}
								>
									{t('MOBILE')}
								</Button>
								<Button
									sx={values.phoneNumberType === PhoneNumberType.BUSINESS ? Styles.SelectedButton : Styles.FormButton}
									onClick={handleFieldChange(FormFields.PHONE_NUMBER_TYPE)}
									value={PhoneNumberType.BUSINESS}
								>
									{t('BUSINESS')}
								</Button>
							</ButtonGroup>
						</Grid>
						<Grid item sm={6} xs={12}>
							<RegistrationPhoneNumberInput
								phoneNumber={values.phoneNumber}
								handlePhoneNumber={handleFieldChange(FormFields.PHONE_NUMBER)}
								onBlur={handleOnBlur(FormFields.PHONE_NUMBER)}
								error={errors.phoneNumber}
								setFlagCountryCode={setFlagCountryCode}
							/>
						</Grid>
					</Grid>
					{/*Hiding the promotional consent for now*/}
					{false && (
						<Stack spacing={2} direction="row" sx={Styles.PromotionalMessagingConsent}>
							<Checkbox value={values.promotionalConsent} onChange={handleFieldChange(FormFields.PROMOTIONAL_CONSENT)} sx={Styles.Checkbox} />
							<Typography variant="caption">
								<Trans
									i18nKey="REGISTRATION_V2_PROMOTIONAL_CONSENT"
									components={[
										<Link
											sx={Styles.Caption}
											href="https://www.chrobinson.com/en/-/media/chrobinson/documents/terms-conditions/chrobinson-carrier-sms-terms-and-conditions.pdf"
											target="_blank"
											rel="noopener"
										>
											{t('TERMS_AND_CONDITIONS')}
										</Link>,
										<Link sx={Styles.Caption} href="https://www.chrobinson.com/en-us/privacy-notice/" target="_blank" rel="noopener">
											{t('GLOBAL_PRIVACY_NOTICE')}
										</Link>,
									]}
								/>
							</Typography>
						</Stack>
					)}
				</Box>
				<Box sx={Styles.SubmissionButtonContainer}>
					{formHasErrors && !isLoading && <EdsStyleErrorMessage errorMessage={t('ENSURE_ALL_FIELDS_ARE_COMPLETED')} />}
					<Button
					  variant="contained"
					  disabled={isCarrierCodeFocused || isLoading}
					  onClick={handleSubmission}
					  sx={Styles.SubmissionButton} data-testid="submit-button"
					>
						{t('SUBMIT')}
					</Button>
				</Box>
			</Box>
			<Box>
				<Typography variant="body1" fontWeight="bold">
					{t('QUESTIONS')}
				</Typography>
				<Typography variant="body1">
					<Trans i18nKey="CHAT_OR_EMAIL_US_0" components={[<CustomerSupportEmail />]} />
				</Typography>
			</Box>
			<TurnstileCaptchaComponent
				isEnabled={isEnabled}
				captchaKey={CaptchaStorageKeys.registrationSubmission}
			/>
		</Box>
	);
}
