import React from 'react';
import qs from 'qs';
import { Container, Inject, Service } from 'typedi';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/reduce';

import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { Carrier } from 'shared/models/carrier/carrier.model';
import { CostQuote } from 'shared/models/rates/cost-quote.model';
import { Claim } from 'shared/models/claims/claim.model';
import { CarrierVoucher } from 'shared/models/voucher/carrier-voucher.model';
import { ResultSet } from 'shared/models/result-set.model';
import { ExtendedLoad } from 'shared/models/loads/extended-load.model';
import { LoadProblem } from 'shared/models/loads/load-problems/load-problem.model';
import { AccessorialCustomer } from 'shared/models/loads/customer/accessorial-customer.model';
import { ExtendedStop } from 'shared/models/loads/stops/extended-stop.model';
import { LoadSummary } from 'shared/models/loads/load-summaries/load-summary.model';
import { BaseLoadBook } from 'shared/models/loads/load-books/base-load-book.model';
import { User } from 'shared/models/user.model';
import { MimeType, Headers } from 'app/globals/constants';
import { SpotBidSummary } from 'shared/models/spot-bids/spot-bid-summary.model';
import { SpotBidDetail } from 'shared/models/spot-bids/spot-bid-detail.model';
import { SpotBidDocumentSummary } from 'shared/models/spot-bids/spot-bid-document-summary.model';
import { Invoice } from 'shared/models/invoice/invoice.model';
import { InvoiceDetail } from 'shared/models/invoice/invoice-detail.model';
import { InvoiceComment } from 'shared/models/invoice/invoice-comment.model';
import { FinancialLoadProblem } from 'shared/models/my-loads/financials/financial-load-problem.model';
import { TMCAccessorial } from 'shared/models/my-loads/financials/tmc-accessorial.model';
import { CarrierCheckDetail } from 'shared/models/voucher/carrier-check-detail.model';
import { ToastManager } from 'shared/components/toast/toast.actions';
import { Translation } from 'shared/components/translation/translation.component';
import { CarrierCheckReport } from 'shared/models/voucher/carrier-check-report.model';
import { VoucherFilterType } from 'shared/enums/voucher-filter-type.enum';
import { COUNTRIES_WITH_STATES } from 'app/globals/constants';
import { CarrierVoucherDetail } from 'shared/models/voucher/carrier-voucher-detail.model';

@Service()
export class InterimRepository extends CacheableRepository {
  @Inject('apiConfig.interimAPI')
  protected baseUrl;
  toasts = Container.get(ToastManager);

  // CARRIERS
  public getCarriers(): Observable<Carrier[]> {
    return this.getWithCache('/Carriers/')
      .map(carriers => carriers.map(json => new Carrier(json)))
      .catch(err => {
        this.toasts.error([<Translation resource="AN_ERROR_OCCURED_ATTEMPTING_TO_LOAD_THE_PAGE" />]);
        return Observable.empty();
      });
  }

  public getReasonCodes(isEarly: boolean, customerCode: string, carrierCode: string): Observable<ReferenceDataJSON[]> {
    const queryString = this.generateQueryString({
      isEarly: `${(isEarly || false)}`,
      customerCode: customerCode,
      carrierCode: carrierCode
    });

    return this.get(`/RefData/OutsideApptReasonCodes${queryString}`)
      .map(reasonsObj => {
        return Object.keys(reasonsObj).map(key => {
          return { code: key, description: reasonsObj[key] };
        });
      });
  }

  public getLoadAlerts(carrierCode): Observable<any[]> {
    const queryString = this.generateQueryString({ carrierCode: carrierCode });
    return this.get(`/LoadAlerts${queryString}`);
  }

  public getCustomers(carrierCode: string): Observable<CustomerJSON[]> {
    return this.getWithCache(`/Carriers/${carrierCode}/CostQuotes/Customers/`);
  }

  // COST QUOTES / RATES

  public getCostQuotes(carrierCode: string, criteria: PageableCostQuoteSearchCriteria): Observable<ResultSet<CostQuote>> {
    const queryString = this.generateQueryString(criteria);
    return this.get(`/Carriers/${carrierCode}/CostQuotes${queryString}`)
      .map(response => new ResultSet(response, (costQuote: CostQuoteJSON) => new CostQuote(costQuote)));
  }

  // CLAIMS

  public searchClaims(carrierCode: string, criteria): Observable<ResultSet<Claim>> {
    return this.post(`/Carriers/Searches/${carrierCode}/Claims`, criteria)
      .map(response => new ResultSet(response, (claim: ClaimJSON) => new Claim(claim)));
  }

  // ACCOUNTS RECEIVABLE

  public getCarrierDetails(): Observable<Carrier> {
    return this.get(`/Carrier`);
  }

  public getVouchers(carrierCode: string, criteria): Observable<ResultSet<CarrierVoucher>> {
    return this.post(`/Carriers/${carrierCode}/Vouchers`, criteria)
      .map(response => new ResultSet(response, (voucher: VoucherJSON) => new CarrierVoucher(voucher)));
  }

  public getVoucherPaymentStatus(carrierCode: string, loadNumber: number, filterType: VoucherFilterType, status: string): Observable<PaymentStatusJSON> {
    const queryString = qs.stringify({ filterType, status }, { addQueryPrefix: true });
    return this.get(`/Carriers/${carrierCode}/Loads/${loadNumber}/PaymentStatus${queryString}`);
  }

  public getCheckDetail(carrierCode: string, checkId: string): Observable<CarrierCheckDetail> {
    return this.get(`/Carriers/${carrierCode}/CheckDetail/${checkId}`)
      .map(detail => new CarrierCheckDetail(detail));
  }

  public getCheckReport(carrierCode: string, criteria): Observable<ResultSet<CarrierCheckReport>> {
    return this.post(`/Carriers/${carrierCode}/CheckReport`, criteria)
      .map(response => new ResultSet(response, (checkReport: CheckReportJSON) => new CarrierCheckReport(checkReport)));
  }

  public getCarrierVouchers(carrierCode: string, loadNumber: number): Observable<any[]> {
    return this.get(`/Carriers/${carrierCode}/Loads/${loadNumber}/VoucherDetails`)
      .map(response => {
        return response;
      });
  }

  public getARInvoiceDetails(carrierCode: string, loadNumber: number, voucherId?: number, voucherDocumentId?: number): Observable<CarrierVoucherDetail[]> {
    let url: string = `/Carriers/${carrierCode}/Loads/${loadNumber}/VoucherDetails`;

    if (voucherId && voucherDocumentId) {
      url = `${url}?VoucherId=${voucherId}&voucherDocumentId=${voucherDocumentId}`;
    }

    return this.get(url)
      .map(results => results?.map(result => new CarrierVoucherDetail(result)));
  }

  /* tslint:enable:max-line-length */

  // MY LOADS

  public getLoadDetails(loadNumber: number): Observable<ExtendedLoad> {
    return this.get(`/Loads/${loadNumber}`)
      .map((load) => new ExtendedLoad(load));
  }

  public getLoad(loadNumber: number, bookType: string, carrierCode: string = null): Observable<ExtendedLoad> {
    const queryString = this.generateQueryString({ bookType, carrierCode });
    return this.get(`/Loads/${loadNumber}${queryString}`)
      .map((load) => new ExtendedLoad(load));
  }

  public updateLoad(loadNumber: number, load: ExtendedLoad): Observable<ExtendedLoad> {
    const queryString = this.generateQueryString({ updateType: 'WayBillUpdate' });
    const loadUrl = `/Loads/${loadNumber}${queryString}`;
    return this.patch(loadUrl, JSON.stringify(load.toJson()))
      .map(json => new ExtendedLoad(json));
  }

  public getGroup(groupId: string): Observable<ExtendedLoad[]> {
    return this.get(`/Groups/${groupId}/Loads`)
      .map(loads => loads.map(load => new ExtendedLoad(load)));
  }

  public createLoadProblem(loadNumber: number, bookSequenceNumber: number, stopNumber: number, loadProblem: LoadProblem): Observable<LoadProblem> {
    const loadProblemUrl = `/Loads/${loadNumber}/Books/${bookSequenceNumber}/Stops/${stopNumber}/LoadProblems`;
    return this.post(loadProblemUrl, JSON.stringify(loadProblem.toPostJson()))
      .map(json => new LoadProblem(json));
  }

  /* tslint:disable:max-line-length */
  public updateLoadProblem(loadNumber: number, bookSequenceNumber: number, stopNumber: number, loadProblem: LoadProblem, problemSequenceNumber: string): Observable<LoadProblem> {
    const loadProblemUrl = `/Loads/${loadNumber}/Books/${bookSequenceNumber}/Stops/${stopNumber}/LoadProblems/${problemSequenceNumber}`;

    return this.put(loadProblemUrl, JSON.stringify(loadProblem.toPostJson()))
      .map(json => new LoadProblem(json));
  }

  /* tslint:enable:max-line-length */

  public getAccessorialCustomer(loadNumber: number): Observable<AccessorialCustomer> {
    return this.get(`/Loads/${loadNumber}/AccessorialCustomer`)
      .map(customer => new AccessorialCustomer(customer));
  }

  public getAccessorialChargeTypes(loadNumber: number, stopNumber: number): Observable<any> {
    return this.get(`/Loads/${loadNumber}/Stops/${stopNumber}/AccessorialChargeTypes`);
  }

  public search(query: MyLoadsSearchRequest): Observable<ResultSet<LoadSummary>> {
    return this.post(`/Loads`, query)
      .map(response => new ResultSet(response, json => new LoadSummary(json)));
  }

  public getLoadBooks(loadNumber: number): Observable<BaseLoadBook[]> {
    return this.get(`/Loads/${loadNumber}/Books`)
      .map(books => books.map(book => new BaseLoadBook(book)));
  }

  // STOPS

  public updateStopDetails(load: ExtendedLoad, stop: ExtendedStop): Observable<ExtendedStop> {
    const url = `/Loads/${load.number}/Books/${load.loadBook.sequenceNumber}/Stops/${stop.number}`;
    return this.patch(url,
      JSON.stringify(
        {
          bookType: load?.loadBook?.bookType ? load.loadBook.bookType : null,
          carrierId: load?.loadBook?.carrier?.code ? load.loadBook.carrier.code : null,
          ...stop.toJson()
        }
      ))
      .map(stopJSON => new ExtendedStop(stopJSON));
  }

  // TRAILER

  getTrailer(loadNumbers: number[]): Observable<ILoadTrailer> {
    const queryString = this.generateQueryString({ loadNumbers: loadNumbers.join() });
    return this.get(`/LoadTrailers${queryString}`);
  }

  saveTrailer(trailerDetails: ILoadTrailer): Observable<ILoadTrailer> {
    return this.post('/LoadTrailers', trailerDetails);
  }

  /*  SPOT BIDS */

  public getSpotBids(request: SpotBidsSearchRequest): Observable<ResultSet<SpotBidSummary>> {
    return this.get(`/SpotBids${this.generateQueryString(request)}`)
      .map((response: ResultSetJSON<SpotBidSummaryJSON>) => {
        return new ResultSet(response, item => new SpotBidSummary(item));
      });
  }

  public getSpotBidDetails(id: string, carrierCode: string): Observable<SpotBidDetail> {
    const queryString = qs.stringify({ carrierCode }, { addQueryPrefix: true, skipNulls: true });
    return this.get(`/SpotBids/${id}${queryString}`)
      .map(detail => new SpotBidDetail(detail));
  }

  public getSpotBidDocuments(id: number, carrierCode: string): Observable<SpotBidDocumentSummary[]> {
    const queryString = qs.stringify({ carrierCode }, { addQueryPrefix: true, skipNulls: true });
    return this.get(`/SpotBids/${id}/Documents${queryString}`)
      .map(docs => docs.map(doc => new SpotBidDocumentSummary(doc)));
  }

  public downloadSpotBidDocument(bid: SpotBidDetail, carrierCode: string, document: SpotBidDocumentSummary): Observable<Blob> {
    const queryString = qs.stringify({ carrierCode }, { addQueryPrefix: true, skipNulls: true });
    const mimeType = MimeType.getByExtension(document.fileExtension);

    return this.getBlob(`/SpotBids/${bid.number}/Documents/${document.documentId}/File${queryString}`, { [Headers.ACCEPT]: mimeType });
  }

  public uploadSpotBidDocument(loadNumber: number, carrierCode: string, uploadData: FormData): Observable<void> {
    const queryString = qs.stringify({ carrierCode }, { addQueryPrefix: true, skipNulls: true });
    const headers = { [Headers.CONTENT_TYPE]: 'multipart/form-data' };

    return this.postWithMultipart(`/SpotBids/${loadNumber}/Documents${queryString}`, uploadData, headers);
  }

  public deleteSpotBidOffers(id: string, carrierCode: string): Observable<void> {
    const queryString = qs.stringify({ carrierCode }, { addQueryPrefix: true, skipNulls: true });
    return this.delete(`/SpotBids/${id}/Offers${queryString}`);
  }

  public makeOffer(id: string, carrierCode: string, offer: SpotBidOfferRequest): Observable<SpotBidOfferRequest> {
    const queryString = qs.stringify({ carrierCode }, { addQueryPrefix: true, skipNulls: true });
    return this.post(`/SpotBids/${id}/Offers${queryString}`, JSON.stringify(offer));
  }

  // TMC REPORTS

  public getTMCReportTickets(user: User) {
    return this.get('/TmcReportTickets');
  }

  // TMC Manage Invoices

  public getInvoiceCustomers(carrierCode: string): Observable<CustomerJSON[]> {
    return this.getWithCache(`/Carriers/${carrierCode}/Invoices/Customers/`);
  }

  public getSCACs(carrierCode: string): Observable<string[]> {
    return this.getWithCache(`/Carriers/${carrierCode}/Invoices/ScacCodes/`);
  }

  public getCarrierInvoiceStatuses(): Observable<{ [key: string]: number }> {
    return this.getWithCache('/ReferenceData?categoryCode=CarrierInvoiceStatus');
  }

  public searchInvoices(carrierCode: string, criteria: ManageInvoicesSearchRequest): Observable<ResultSet<Invoice>> {
    return this.get(`/Carriers/${carrierCode}/Invoices${this.generateQueryString(criteria)}`)
      .map(invoiceResultSet => new ResultSet(invoiceResultSet, json => new Invoice(json)));
  }

  public getInvoiceDetails(carrierCode: string, invoiceNumber: number): Observable<InvoiceDetail> {
    return this.get(`/Carriers/${carrierCode}/Invoices/${invoiceNumber}/Detail`)
      .map(json => new InvoiceDetail(json));
  }

  public createInvoice(carrierCode: string, invoice: InvoiceDetail) {
    return this.post(`/Carriers/${carrierCode}/Invoices`, invoice.toJson())
      .map(json => new InvoiceDetail(json));
  }

  public getBatchUploadCustomers(carrierCode: string): Observable<string[]> {
    return this.getWithCache(`/Carriers/${carrierCode}/Invoices/BatchDocuments/Customers`);
  }

  public uploadInvoices(carrierCode: string, uploadData: FormData): Observable<void> {
    const headers = { [Headers.CONTENT_TYPE]: 'multipart/form-data' };

    return this.postWithMultipart(`/Carriers/${carrierCode}/Invoices/BatchDocuments`, uploadData, headers);
  }

  /** FINANCIALS **/
  public addAccessorialRequest(load: ExtendedLoad, request: FinancialLoadProblem): Observable<FinancialLoadProblem> {
    return this.post(`/Loads/${load.number}/Financials/LoadProblems`,
      {
        bookType: load?.loadBook?.bookType ? load.loadBook.bookType : null,
        carrierId: load?.loadBook?.carrier?.code ? load.loadBook.carrier.code : null,
        ...request.toJson()
      }
    );
  }

  public addAccessorialRequestV2(loadNumber: number, bookType: number, carrierId: number, request: FinancialLoadProblem): Observable<FinancialLoadProblem> {
    return this.post(`/Loads/${loadNumber}/Financials/LoadProblems`,
      {
        bookType: bookType ? bookType : null,
        carrierId: carrierId ? carrierId : null,
        ...request.toJson()
      }
    );
  }

  public updateAccessorialRequest(load: ExtendedLoad, request: FinancialLoadProblem): Observable<FinancialLoadProblem> {
    return this.put(`/Loads/${load.number}/Financials/LoadProblems/${request.sequenceNumber}`,
      {
        bookType: load?.loadBook?.bookType ? load.loadBook.bookType : null,
        carrierId: load?.loadBook?.carrier?.code ? load.loadBook.carrier.code : null,
        ...request.toJson()
      }
    );
  }

  public updateAccessorialRequestV2(loadNumber: number, bookType: number, carrierId: number,  request: FinancialLoadProblem): Observable<FinancialLoadProblem> {
    return this.put(`/Loads/${loadNumber}/Financials/LoadProblems/${request.sequenceNumber}`,
      {
        bookType: bookType ? bookType : null,
        carrierId: carrierId ? carrierId : null,
        ...request.toJson()
      }
    );
  }

  public cancelAccessorialRequest(load: ExtendedLoad, request: FinancialLoadProblem): Observable<FinancialLoadProblem> {
    return this.delete(`/Loads/${load.number}/Financials/LoadProblems/${request.sequenceNumber}`);
  }

  public addTMCAccessorial(load: ExtendedLoad, accessorial: TMCAccessorial): Observable<TMCAccessorial> {
    return this.post(`/Loads/${load.number}/Financials/AccessorialRequests`,
      {
        bookType: load?.loadBook?.bookType ? load.loadBook.bookType : null,
        carrierId: load?.loadBook?.carrier?.code ? load.loadBook.carrier.code : null,
        ...accessorial.toJson()
      }
    );
  }

  public updateTMCAccessorial(load: ExtendedLoad, accessorial: TMCAccessorial): Observable<TMCAccessorial> {
    return this.put(`/Loads/${load.number}/Financials/AccessorialRequests/${accessorial.accessorialRequestId}`,
      {
        bookType: load?.loadBook?.bookType ? load.loadBook.bookType : null,
        carrierId: load?.loadBook?.carrier?.code ? load.loadBook.carrier.code : null,
        ...accessorial.toJson()
      }
    );
  }

  public deleteTMCAccessorial(load: ExtendedLoadInterface, accessorial: AccessorialRateInterface, carrierCode: string): Observable<void> {
    return this.delete(`/Loads/${load.number}/Financials/AccessorialRequests/${accessorial.loadProblemSequenceNumber}?carrierCode=${carrierCode}`);
  }
}
