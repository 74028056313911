import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  required?: boolean;
  className?: string;
  resource?: ResourceKey;
}

type Props = { [key: string]: any } & OwnProps;

export const Label = ({ resource, required, className, ...props }: Props) => {
  const { t } = useTranslation();
  return (
    <label {...props} className={classNames(className, { required })}>
      {Boolean(resource) && t(resource)}
      {props.children}
    </label>
  );
};
