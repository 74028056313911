import { UpdateBook } from "../types";

export const isIntermodal = (loadBookType: string): boolean => {
    return loadBookType === 'IPU' || loadBookType === 'IDL' || loadBookType === 'IXT' || loadBookType === 'ILH';
};

export const isIntermodalWithoutCross = (loadBookType: string): boolean => {
    return loadBookType === 'IPU' || loadBookType === 'IDL';
};


export const createBookUpdate = (
    proNumber: string, 
    tractorNumber: string, 
    trailerNumber: string, 
    licencePlateNumber: string, 
    driverId: number,
    driverPhoneNumber: string ): UpdateBook => {
    return {
        proNumber: proNumber,
        tractorNumber: tractorNumber,
        trailerNumber: trailerNumber,
        licensePlateNumber: licencePlateNumber,
        drivers: [
            {
                firstName: '',
                lastName: '',
                phoneNumber: driverPhoneNumber,
                driverType: 1,
                contactId: driverId
            }
        ]
    };
}