import classNames from 'classnames';
import { ChangeEvent, Children, ReactElement, useCallback, useMemo, cloneElement } from 'react';
import { SortOrder } from 'shared/enums/sort-order.enum';
import { DataViewFormat } from 'shared/enums/data-view.enum';
import { FooterPosition } from 'shared/enums/data-table-footer-position.enum';
import { HeaderPosition } from 'shared/enums/data-table-header-position.enum';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';
import { DataTableContext, DataTableContextProvider } from 'shared/components/data-table/context/data-table.context.provider';
import { CardSortOption, RenderHeaderProps, SortConfig } from 'shared/components/data-table/data-table.interfaces';
import { CardSortOrderToggle } from 'shared/components/data-table/card-sort-order-toggle.component';
import { ViewSelectors } from 'shared/components/data-table/view-selectors.component';
import { CardSortSelect } from 'shared/components/data-table/card-sort-select.component';
import { PageSize } from 'shared/components/data-table/page-size.component';
import { useSortingCallbacks } from 'shared/components/data-table/hooks/use-sorting-callbacks.hook';
import { useDefaultActionsContext } from 'shared/components/data-table/hooks/use-default-actions-context.hook';
import { useRegistrationFlag } from 'shared/components/data-table/hooks/use-registration-flag.hook';
import { useViewFormat } from 'shared/components/data-table/hooks/use-view-format.hook';
import { useRenderHeaderProps } from 'shared/components/data-table/hooks/use-render-header-props.hook';
import { useEffectiveViewFormat } from 'shared/components/data-table/hooks/use-effective-view-format.hook';
import { useRegisteredPaginator } from 'shared/components/data-table/hooks/use-registered-paginator.hook';
import { RestrictedBanner } from '@pages/find-loads-ver2/results/cap-restricted/restricted-banner.component';
import { LoadNotificationButton } from '@pages/find-loads-ver2/load-notifications/load-notification-button.component';

import './data-table.component.scss';

interface Props {
  className?: string;
  items?: any[];
  totalRecords?: number;
  cardSortOptions?: CardSortOption[];
  onSort?: (sortByColumnName: string, sortOrder: SortOrder) => any;
  onViewChange?: (view: DataViewFormat) => any;
  sortConfig?: SortConfig;
  pagination?: PageableRequest;
  paginationComponent?: JSX.Element[] | JSX.Element;
  pageAriaLabelResource?: ResourceKey;
  pageResource?: ResourceKey;
  pageSizeResource?: ResourceKey;
  filterComponent?: JSX.Element[] | JSX.Element;
  renderHeader?: (props: RenderHeaderProps) => ReactElement<any>;
  renderFooter?: JSX.Element;
  detailComponent?: JSX.Element;
  children: JSX.Element[] | JSX.Element;
  aboveResultsContent?: JSX.Element;
  belowResultsContent?: JSX.Element;
  viewFormatOverride?: DataViewFormat;
  dataTableButton?: JSX.Element | JSX.Element[];
  hideControls?: boolean;
  sortControlDisabled?: boolean;
  footerPosition?: FooterPosition;
  headerPosition?: HeaderPosition;
  addRestrictedBanner?: boolean;
  suggestedHeader?: JSX.Element;
  addLoadNotifications?: boolean;
}

export const DataTable = ({ className, pagination, pageResource, pageAriaLabelResource, pageSizeResource,
  sortConfig = { sortByColumnName: 'number', sortOrder: SortOrder.ASC },
  onSort, totalRecords,
  filterComponent, hideControls, sortControlDisabled, ...props }: Props) => {
  // Sorting
  const { sortOrder, sortByColumnName, includeEmptyOption } = sortConfig;
  const [updateSortOrder, sortByEvent, sortByColumn] = useSortingCallbacks(sortConfig, onSort);

  // Pagination
  const [paginatorFn, registerPaginator] = useRegisteredPaginator();

  const setPageSize = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    if (paginatorFn) {
      paginatorFn(1, Number(e.target.value));
    }
  }, [paginatorFn]);

  // Data View Format
  const [hasCardView, registerCardView] = useRegistrationFlag();
  const [hasGridView, registerGridView] = useRegistrationFlag();
  const [viewFormat, onViewChangeCallback] = useViewFormat(props.onViewChange, props.viewFormatOverride);
  const effectiveViewFormat = useEffectiveViewFormat(viewFormat, hasGridView, hasCardView);

  // context values
  const actions = useDefaultActionsContext(registerCardView, registerGridView, registerPaginator, sortByColumn);
  const context: DataTableContext = useMemo(() => {
    return { format: effectiveViewFormat, hasCard: hasCardView, hasGrid: hasGridView, actions, sortConfig, items: props.items };
  }, [effectiveViewFormat, hasGridView, hasCardView, actions, sortConfig, props.items]);

  // for when a user provides a render header callback
  const renderHeaderProps = useRenderHeaderProps(sortByColumnName, sortOrder, props.cardSortOptions, pagination, sortConfig, totalRecords, updateSortOrder, sortByEvent, setPageSize);

  // for translation replacements (from X to Y)
  const startIndex = useMemo(() => pagination ? (pagination.pageNumber - 1) * pagination.pageSize + 1 : '', [pagination]);
  const endIndex = useMemo(() => pagination ? Math.min((pagination.pageNumber * pagination.pageSize), totalRecords) : '', [pagination, totalRecords]);
  const { t } = useTranslation();

  let newButton;
  const rawTableButtons = Children.toArray(props.dataTableButton);

  if (rawTableButtons) {
    newButton = rawTableButtons.map((buttonElement: any) => {
      return cloneElement(buttonElement, {
        className: (buttonElement.props.className ? `${buttonElement.props.className} ` : '') + 'button-control'
      });
    });
  }

  const footer = () => {
    if (props.renderFooter) {
      return props.renderFooter;
    }

    return (
      <div className="table-footer">
        <div className="below-results-container">{props.belowResultsContent}</div>
        <div className="pagination-container">{props.paginationComponent}</div>
        <div className="page-size-container">
          {Boolean(paginatorFn) &&
            <PageSize pageSize={pagination.pageSize} paginate={setPageSize} pageSizeResource={pageSizeResource} />
          }
        </div>
        <div className="below-results-container mobile">{props.belowResultsContent}</div>
      </div>
    );
  };

  const header = () => {
    if (props.renderHeader) {
      return props.renderHeader(renderHeaderProps);
    }

    return (
      <>
        <div className={`table-header desktop-view ${hideControls ? 'hideControls' : ''}`}>
          {Boolean(pagination && totalRecords) &&
            <div className="content-bay">
              <span className="pagination-range">
                {!pageResource || pageAriaLabelResource && (
                  // Screen reader label to read before the result count. If no pageResource is provided, use default value. Otherwise, only use if one is explicitly provided.
                  <span className="sr-only">
                    ({t(pageAriaLabelResource || 'NUM_OF_RESULTS')})
                  </span>
                )}
                {t(pageResource ?? '0_1_OF_2', { 0: startIndex, 1: endIndex, 2: totalRecords })}
              </span>
              {props.aboveResultsContent}
            </div>
          }
          {Boolean(props.addLoadNotifications && !hideControls) &&
            <LoadNotificationButton />
          }
          <div className="button-bay">
            <div>{filterComponent}</div>
            {Boolean(paginatorFn) &&
              <PageSize pageSize={pagination.pageSize} paginate={setPageSize} pageSizeResource={pageSizeResource} />
            }
            {!hideControls &&
              <>
                <CardSortSelect
                  className={classNames('no-wrap space-outer-left-md', { 'grid-view-hidden': viewFormat !== DataViewFormat.Card })}
                  viewFormat={viewFormat}
                  sortByColumnName={sortByColumnName}
                  sortByEvent={sortByEvent}
                  cardSortOptions={props.cardSortOptions}
                  includeEmptyOption={includeEmptyOption}
                />
                <CardSortOrderToggle
                  className={classNames('space-outer-left-sm', { 'grid-view-hidden': viewFormat !== DataViewFormat.Card })}
                  sortOrder={sortOrder}
                  onClick={updateSortOrder}
                  sortOrderDisabled={sortControlDisabled}
                />
                {(hasGridView && hasCardView) &&
                  <ViewSelectors className="space-outer-left-md" onChange={onViewChangeCallback} viewFormat={viewFormat} />
                }
              </>
            }
            {newButton ? newButton : props.dataTableButton}
          </div>
        </div>
        <div className="table-header mobile-view">
          <div className="flex align-center">
            {Boolean(pagination && totalRecords) &&
              <span className="pagination-range flex flex-align-items-center">
                {t(pageResource ?? '0_1_OF_2', { 0: startIndex, 1: endIndex, 2: totalRecords })}
              </span>
            }
            {Boolean(props.addLoadNotifications && !hideControls && totalRecords) &&
              <LoadNotificationButton />
            }
            {newButton ? newButton : props.dataTableButton}
          </div>
          <div className="flex flex-space-between flex-align-items-end">
            {!hideControls &&
              <>
                <CardSortSelect
                  className="full-width"
                  id="mobile"
                  viewFormat={viewFormat}
                  sortByColumnName={sortByColumnName}
                  sortByEvent={sortByEvent}
                  cardSortOptions={props.cardSortOptions}
                  includeEmptyOption={includeEmptyOption}
                />
                <CardSortOrderToggle
                  className={classNames('space-outer-left-md', { 'grid-view-hidden': viewFormat !== DataViewFormat.Card })}
                  sortOrder={sortOrder}
                  onClick={updateSortOrder}
                />
              </>
            }
          </div>
          <div className="space-outer-top-md">{filterComponent}</div>
          <div className="space-outer-top-md">{props.aboveResultsContent}</div>
        </div>
      </>
    );
  };

  return (
    <div className={classNames('data-table-component', (viewFormat !== DataViewFormat.Card ? 'showing-grid' : 'showing-card'), className)}>
      <ErrorBoundary className="space-outer-top-lg">
        <DataTableContextProvider value={context}>
          {props.headerPosition !== HeaderPosition.aboveTableContent && header()}
          <div className={`table-container ${props.detailComponent ? 'has-detail' : ''}`}>
            <div className="table-contents">
              {props.suggestedHeader}
              {props.addRestrictedBanner && <RestrictedBanner />}
              {props.headerPosition === HeaderPosition.aboveTableContent && header()}
              {props.children}
              {props.footerPosition === FooterPosition.belowTableContent && footer()}
            </div>
            {props.detailComponent &&
              <div className="table-content-detail">
                {props.detailComponent}
              </div>
            }
          </div>
          {props.footerPosition !== FooterPosition.belowTableContent && footer()}
        </DataTableContextProvider>
      </ErrorBoundary>
    </div>
  );
};
