import { Driver } from 'shared/models/contacts/driver.model';
import { BaseModel } from 'shared/models/base.model';
import { LoadStatus } from 'shared/enums/load-status.enum';
import { StopAddress } from 'shared/models/stop-address.model';
import { LoadContact } from 'shared/models/contacts/load-contact.model';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';

export class BaseLoad extends BaseModel {
  public number: number;
  public carrierCode: string;
  public bookSequenceNumber: number;
  public currentStopNumber: number;
  public billingStatus = {};
  public checkCallFrequency: number;
  public contact: LoadContact;
  public deliverByDate: Date;
  public equipmentLength: number;
  public equipmentWidth: number;
  public equipmentHeight: number;
  public measurementType: UnitOfMeasure;
  public mode: string;
  public weight: number;
  public distance: number;
  public status: LoadStatusJSON;
  public hasWork = true;
  public rate: number;
  public minTemp;
  public maxTemp;
  public isReadyForPickup = true;
  public proNumber: string;
  public tractorNumber: string;
  public trailerNumber: string;
  public driver?: Driver;
  public isRegulatedBySTF = true;
  public tmcRule: string;
  public trackingMethodCode: string;
  public stopDetails?: StopJSON[];
  public driverLogs?: DriverLogJSON[];
  public expectedEmptyDate?: Date;
  public expectedEmptyAddress?: StopAddress;
  public canAddTracking: boolean;
  public acceptedBy: string;
  public groupID: number;

  constructor(json: BaseLoadJSON) {
    super(json);

    this.deliverByDate = this.processDate(json.deliverByDate);
    this.expectedEmptyDate = this.processDate(json.expectedEmptyDate);

    if (json.contact) {
      this.contact = new LoadContact(json.contact);
    }

    if (json.expectedEmptyAddress) {
      this.expectedEmptyAddress = new StopAddress(json.expectedEmptyAddress);
    }

    if (json.driver) {
      this.driver = new Driver(json.driver);
    }

    if (!this.acceptedBy) {
      this.acceptedBy = 'N/A';
    }

    this.status = {code: null};

    if (json.status != null) {
      this.status.code = json.status;
    }
  }

  isDelivered(): boolean {
    return this.status.code === LoadStatus.Delivered || this.status.code === LoadStatus.Completed;
  }

  isTendered(): boolean {
    return this.status.code === LoadStatus.Tendered;
  }

  getDriverFullName(): string {
    if (!this.driver) {
      return;
    }
    // noinspection JSStringConcatenationToES6Template
    return (this.driver.firstName + ' ' + this.driver.lastName).trim();
  }

  toJson(): BaseLoadJSON {
    const response: any = Object.assign({}, this);

    response.deliverByDate = this.convertDateToDateString(this.deliverByDate);
    response.expectedEmptyDate = this.convertDateToDateString(this.expectedEmptyDate);
    if (this.expectedEmptyAddress) {
      response.expectedEmptyAddress = this.expectedEmptyAddress.toJson();
    }
    response.status = this.status.code;
    if (this.acceptedBy === 'N/A') {
      response.acceptedBy = '';
    }
    response.driver = this.driver.toJson();

    return response;
  }
}
