import './loading-indicator-spinner.component.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  isVisible?: boolean;
  headerText?: ResourceKey;
  mainText?: ResourceKey | string;
}


export const LoadingIndicatorSpinner = ({ isVisible, headerText, mainText }: Props) => {
  const { t } = useTranslation();

  if (!isVisible) {
    return null;
  }

  return (
    <div className="spinner-container centered-spinner" data-testid="spinner-container">
      {
        headerText && <div className="spinner-header-text h3">{t(headerText)}</div>
      }
      <div className="spinner-fade-in">
        <div className="chr-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
      {
        mainText && <div className="spinner-sub-text h2">{t(mainText)}</div>
      }
    </div>
  );
};
