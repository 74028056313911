/* istanbul ignore file */
import { useEffect, useRef, useState, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import { getOktaConfig } from '@features/okta/config';
import { OktaWidgetStringOverrides } from '@features/okta/okta-widget-string-overrides';
import { setLoggedIn } from '@features/okta/redux/oktaSlice';
import { JwtBanner } from '@features/okta/styles';
import { CarriersRepository } from '@app/repositories/carriers.repository';
import { processInvitation } from 'api/userManagement';
import { completeLogin } from '@features/security/auth.actions';
import { AppRoute } from 'app/routesEnum';

import { AccessToken, IDToken, RefreshToken } from '@okta/okta-auth-js/types/lib/oidc/types';
import { UserRepository } from '@app/repositories/user.repository';
import { AuthStatus, Tokens } from '@features/okta/types';
import { InvitationAction } from '@features/invitation';
import { useLoader } from '@app/hooks/use-loader.hook';
import { IS_EPHEMERAL } from '@app/globals/constants';
import { useTranslate } from 'shared/components/translation/translation.component';
import { publishLoginEvent } from 'app/repositories/analytics.repository';
import { push } from 'connected-react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DataDogLogger } from '@features/datadog/DataDogLogger';
import { AuthAction, LogAndGetWidgetErrorAction, OktaContext, useAction } from '@features/datadog';
import { FullStoryAPI } from 'react-fullstory';
import { setUserData } from 'store/epics/fullstory.epics';
import { CarrierDetail } from '@shared/models/carrier';

const LazyOktaWidgetStyles = lazy(() =>
 import(/* webpackChunkName: "okta-widget-css-styles", webpackPrefetch: true */ './OktaWidgetStyles').then(({ OktaWidgetStyles }) => ({
  default: OktaWidgetStyles,
 }))
);

/**
 * Okta: User Login For
 *
 * @export
 * @return {*}
 */
export default function LoginWithOktaForm(): any {
 const { oktaAuth } = useOktaAuth();
 const widgetRef = useRef();
 const translate = useTranslate();
 const track = useAction();
 const dispatch = useDispatch();
 const resources = useSelector<NavCarrierState, Resources>(state => state.resources);
 const loader = useLoader();

 const { findLoadsSendAnalyticsWeb } = useFlags();

 const carrierRepo = new CarriersRepository(apiConfig.carrierAPI);
 const userRepo = new UserRepository(apiConfig.userAPI);

 // Fetch otp and state from query params from email callback verification URI
 // Application should have http://localhost:3000/login/callback
 // as the email callback verification URI
 const queryParams = new URLSearchParams(window.location.search);
 const state = queryParams.get('state');
 const config = getOktaConfig();

 const tokenVisible = queryParams.get('jwtvisible') == 'true';
 const isEphemeralOverride = queryParams.get('override') == 'ephemeral';
 const [token, setToken] = useState('');
 const [showToken, toggleToken] = useState(false);
 const [widgetError, setWidgetError] = useState(false);

 const copyToClipboard = async () => {
  await navigator.clipboard.writeText(token);
  alert('copied');
 };

 const handleInputChange = e => {
  setToken(e.target.value);
 };

 const getCarrierAsync = async (carrierCode: string) => {
  return carrierCode.toUpperCase().startsWith('V') ? null : await carrierRepo.getCarrier(carrierCode);
 };

 const finishLogin = async (tokens: ITokens | Tokens) => {
  try {
   const token = tokens.accessToken || JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken || null;
   const claims = extractClaims(token);

   // limited driver users can only access their accounts via the carrier app
   if (claims.carrierCode === 'TMPCARRIER') {
    dispatch(push(AppRoute.UNABLE_TO_SIGNIN_ON_WEB));
   }

   const promisedValues = await Promise.all([userRepo.loginWithOktaUser(), userRepo.getUser(), getCarrierAsync(claims.carrierCode)]);
   const carrierUser = promisedValues[1] as UserJSON;
   const carrierDetails = promisedValues[2] as CarrierDetail;

   DataDogLogger.trackAction(AuthAction.IsOkta);

   dispatch(
    setLoggedIn({
     tokens,
     status: AuthStatus.ACTIVE,
     carrierUser,
     session: null,
     error: null,
     preferredEmailAddress: carrierUser?.preferredEmailAddress,
     preferredEmailVerifiedDate: new Date(carrierUser?.emailLastVerifiedAt),
    })
   );
   dispatch(completeLogin(carrierUser, carrierDetails));

   if (findLoadsSendAnalyticsWeb) {
    publishLoginEvent(carrierUser);
   }

   // finally, set DD Action of Successful Okta Login
   track(AuthAction.OktaLoginSuccess, carrierUser);

   // Update FullStory context
   const { firstName, lastName, username, emailAddress, userID, userId } = carrierUser;
   const _userid = userID?.toString() || userId?.toString() || 'unknown';

   FullStoryAPI('identify', _userid, {
    displayName: `${firstName} ${lastName}`,
    companyName: carrierDetails?.name,
    carrierCode: carrierDetails?.carrierCode,
    userName: username,
    email: emailAddress,
   });
  } catch (error) {
   console.error(error);
  } finally {
   loader.hide();
  }
 };

 const handleWidgetResponse = async ({ tokens, status }: IOktaLoginResponse) => {
  if (status == 'SUCCESS') {
   const invitationToken = queryParams.get('invitationToken');

   // If the user comes from an email invitation, we only accept it after credentials are set successfully
   // and we stop the flow if the accept invitation request fails
   if (invitationToken) {
    try {
     await processInvitation({ token: invitationToken, action: InvitationAction.ACCEPT });
    } catch (error) {
     // TODO: Probably have a screen for errors in the future.
     console.error(error);

     return;
    }
   }

   oktaAuth.handleLoginRedirect(tokens);
   oktaAuth.tokenManager.setTokens(tokens);
   setToken(tokens.accessToken.accessToken);

   // If the user (dev) has put `...&jwtvisible=true`,
   // // don't finish login, this was just to obtain tokens
   if (tokenVisible && !showToken) {
    toggleToken(true);
    return;
   }

   await finishLogin(tokens);
  }
 };

 useEffect(() => {
  if (!widgetRef.current || !Object.keys(resources ?? {}).length) {
   return;
  }

  // Since Okta is using the "Reset your password" form for both new password creation and reset existing password,
  // we will conditionally change the form title base on the workflow indication get send via the url query param.
  for (const key in OktaWidgetStringOverrides) {
   if (queryParams.get('workflow') === 'activate') {
    OktaWidgetStringOverrides[key]['password.reset.title.generic'] = translate('CREATE_PASSWORD');
   }
  }

  const widget = new OktaSignIn({
   ...config,
   state,
   // Check for a recovery token from redirects to determine the appropriate flow for the widget to display.
   recoveryToken: queryParams.get('token') ?? '',
   i18n: OktaWidgetStringOverrides,
   helpLinks: {
    custom: [
     {
      text: translate('NO_ACCOUNT_SIGN_UP'),
      href: '/registration',
     },
    ],
   },
   features: {
    selfServiceUnlock: true,
   },
  });

  widget.renderEl(
   { el: widgetRef.current },
   async (res: any) => handleWidgetResponse(res as IOktaLoginResponse),
   err => {
    // TODO: Log this f@*&$ng error to DataDog properly!
    throw err;
   }
  );

  widget.on('afterError', (context, error) => {
   console.error(`Okta Widget Error: ${error?.message}`);
   console.error(error);
   const errorAction = LogAndGetWidgetErrorAction(context?.controller as OktaContext, error);
   track(errorAction, { error, context });

   if (context?.controller === 'recovery-loading' && error?.statusCode === 403) {
    setWidgetError(true);
   }
  });

  return () => widget.remove();
 }, [oktaAuth, resources]);

 const redirectClicked = async e => {
  const { tokens } = await oktaAuth.token.getWithPopup({ ...config, popupTitle: 'Navisphere Carrier Authenticator', pkce: true });
  oktaAuth.tokenManager.setTokens(tokens);
  finishLogin(tokens);
 };

 return (
  <>
   <LazyOktaWidgetStyles />
   <div ref={widgetRef} />
   {(IS_EPHEMERAL || isEphemeralOverride) && (
    <div style={{ width: '100%', textAlign: 'center' }}>
     <button className="btn btn-secondary" style={{ margin: 5 }} onClick={redirectClicked}>
      Login with Redirect
     </button>
    </div>
   )}
   {widgetError && (
    <div style={{ width: '100%', textAlign: 'center' }}>
     <button className="btn btn-primary" style={{ margin: 5 }} onClick={() => (window.location.href = AppRoute.LOGIN)}>
      {translate('BACK_TO_LOGIN' as keyof IResources)}
     </button>
    </div>
   )}
   {showToken && (
    <div style={{ ...JwtBanner }}>
     <input type="text" value={token} title="Click to copy!" onChange={handleInputChange} style={{ width: '85%', margin: 5, borderRadius: 6 }} />
     <button type="button" onClick={copyToClipboard} className="btn btn-primary" style={{ margin: 5 }}>
      {' '}
      Click to Copy
     </button>
    </div>
   )}
  </>
 );
}

export interface IOktaLoginResponse {
 code: string;
 state: string;
 status: 'SUCCESS' | string;
 tokens: {
  accessToken: AccessToken;
  refreshToken: RefreshToken;
  iDToken: IDToken;
 };
}

interface IClaims {
 userId: string;
 carrierCode: string;
 carrierId: string;
 permissions: string;
 username: string;
}

const extractClaims = (token: AccessToken) =>
 ({
  carrierCode: token.claims['carrierCode'],
  carrierId: token.claims['carrierId'],
  // TODO: Actually map the permissions below
  permissions: token.claims['carrierPermissions'],
  username: token.claims['carrierUsername'],
  userId: token.claims['userId'],
 }) as IClaims;

interface ITokens {
 accessToken: AccessToken;
 iDToken: IDToken;
 refreshToken: RefreshToken;
}
