import classNames from 'classnames';
import { translate } from 'shared/components/translation/translate';
import { useResources } from 'app/hooks/store/use-resources.hook';

interface OwnProps {
  required?: boolean;
  className?: string;
  resource?: ResourceKey;
  replacements?: any[];
}

type Props = { [key: string]: any } & OwnProps;

export const Option = ({ resource, className, dispatch, replacements, ...props }: Props) => {
  const resources = useResources();
  return (
    <option {...props} className={classNames(className)}>
      {translate(resources, resource, replacements)}
      {props.children}
    </option>
  );
};
