import { createSlice } from '@reduxjs/toolkit';
import { ShipmentsRepository } from '@app/repositories/shipmentsV2.repository';
import {
  BookLoadUpdateNotification,
  LoadSummary,
  CashAdvances,
  FetchMyLoadsCriteria,
  CreateAppointment,
} from 'features/my-loads-v2/types';
import { MyLoadsRepository } from '@app/repositories/my-loads.repository';
import { InterimRepository } from 'app/repositories/interim.repository';
import { FinancialsRepository } from '@app/repositories/financials.repository';
import { Container } from 'typedi';
import { setLoadDetails } from '@pages/load-details/load-details.actions';
import {
  fetchCashAdvanceOptionsSuccess
} from '@pages/load-details/sections/cash-advance/redux/cash-advance-options.actions';
import { updateItemInLoadsList, getIndexToUpdate, getUpdatedLoadListResult } from './helpers/loadsListHelper';
import { isIntermodal } from './helpers/loadHelper';

const myLoadsInitialState = {
  loadsTotalCount: 0,
  quickFilters: {
    loadsAllCount: 0,
    loadsTodayCount: 0,
    loadsTomorrowCount: 0,
    loadsOvermorrowCount: 0,
  },
  loadsList: [] as LoadSummary[],
  isLoading: false,
  areStopsLoading: false,
  areLoadDetailsLoading: false,
  areMyLoadDetailsLoading: false,
  areReferenceNumbersLoading: false,
  areChargesLoading: false,
  isCashAdvanceLoading: false,
  isFinancialHistoryLoading: false,
  areDriverLogsLoading: false,
  errorMessage: null,
  loadDetailErrorMessage: null,
  loadStopsErrorMessage: null,
  driverLogsErrorMessage: null,
  myLoadDetailErrorMessage: null,
  referenceNumbersErrorMessage: null,
  cashAdvanceErrorMessage: null,
  financialHistoryError: null,
  isCreateAppointmentLoading: null,
  createAppointmentError: null,
};

const shipments = new ShipmentsRepository();
const interim = Container.get(InterimRepository);
const myLoadsRepository = new MyLoadsRepository();
const financials = new FinancialsRepository();

export const myLoadsBaseSlice = createSlice({
  name: 'myLoadsBase',
  initialState: myLoadsInitialState,
  reducers: {
    getMyLoads: (state, action) => {
      const listToUpdate = [];
      action.payload?.items.map(load => {
        listToUpdate.push({
          ...load,
          isPendingState: load?.processStatuses?.some(item => item?.completedTimestampUtc === null),
        });
      });

      return {
        ...state,
        loadsList: listToUpdate,
        loadsTotalCount: action.payload?.totalCount,
        quickFilters: {
          loadsTodayCount: action.payload?.todayCount,
          loadsAllCount: action.payload?.allCount,
          loadsTomorrowCount: action.payload?.tomorrowCount,
          loadsOvermorrowCount: action.payload?.overmorrowCount,
        },
        isLoading: false,
      };
    },
    getMyLoadsRejected: (state) => {
      return {
        ...state,
        errorMessage: 'There was a problem with fetching list of loads',
        isLoading: false,
      };
    },
    getMyLoadsPending: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },

    setLoadSavedState: (state, action) => {
      const { loadNumber, bookId } = action.payload;
      let listToUpdate = state.loadsList;
      const indexToUpdate = getIndexToUpdate(listToUpdate, loadNumber, bookId);
      
      const newValues = {
        isPendingState: false,
      };
      listToUpdate = updateItemInLoadsList(listToUpdate, newValues, indexToUpdate);

      return getUpdatedLoadListResult(listToUpdate, state);
    },

    setLoadPendingState: (state, action) => {
      const { loadNumber, bookId } = action.payload;
      let listToUpdate = state.loadsList;
      const indexToUpdate = getIndexToUpdate(listToUpdate, loadNumber, bookId);

      const newValues = {
        isPendingState: true,
      };
      listToUpdate = updateItemInLoadsList(listToUpdate, newValues, indexToUpdate);

      return getUpdatedLoadListResult(listToUpdate, state);
    },

    updateLoadInformation: (state, action) => {
      const {
        shipmentId,
        bookId,
        tractorNumber,
        trailerNumber,
        proNumber,
        licensePlateNumber,
        contactId,
        driverFirstName,
        driverLastName,
        shipmentStatus
      } = action.payload;
      let listToUpdate = state.loadsList;
      const indexToUpdate = getIndexToUpdate(listToUpdate, shipmentId, bookId);

      const newValues = {
        loadStatus: shipmentStatus ? shipmentStatus : listToUpdate[indexToUpdate]?.loadStatus,
        tractorNumber: tractorNumber,
        trailerNumber: trailerNumber,
        proNumber: proNumber,
        licensePlateNumber: licensePlateNumber,
        driver: {
          ...listToUpdate[indexToUpdate]?.driver,
          mdmContactId: contactId,
          firstName: driverFirstName,
          lastName: driverLastName
        }
      };
      listToUpdate = updateItemInLoadsList(listToUpdate, newValues, indexToUpdate);

      return getUpdatedLoadListResult(listToUpdate, state);
    },

    getLoadStops: (state, action) => {
      const { loadNumber, bookId } = action.payload;
      const stops = action.payload.response;
      let listToUpdate = state.loadsList;
      const indexToUpdate = getIndexToUpdate(listToUpdate, loadNumber, bookId);

      const newValues = {
        stops: stops,
      };
      listToUpdate = updateItemInLoadsList(listToUpdate, newValues, indexToUpdate);

      const result = getUpdatedLoadListResult(listToUpdate, state);
      return { ...result, areStopsLoading: false, };
    },
    getLoadStopsPending: (state) => {
      return {
        ...state,
        areStopsLoading: true,
      };
    },
    getLoadStopsRejected: (state) => {
      return {
        ...state,
        areStopsLoading: false,
        loadStopsErrorMessage: 'There was an error while fetching a load stops'
      };
    },

    getMyLoadDetails: (state, action) => {
      const { loadNumber, bookId } = action.payload;
      const details = action.payload.response;
      let listToUpdate = state.loadsList;
      const indexToUpdate = getIndexToUpdate(listToUpdate, loadNumber, bookId);

      listToUpdate = updateItemInLoadsList(listToUpdate, details, indexToUpdate);

      const result = getUpdatedLoadListResult(listToUpdate, state);
      return { ...result, areMyLoadDetailsLoading: false, };
    },
    getMyLoadDetailsPending: (state) => {
      return {
        ...state,
        areMyLoadDetailsLoading: true,
      };
    },
    getMyLoadDetailsRejected: (state) => {
      return {
        ...state,
        areMyLoadDetailsLoading: false,
        myLoadDetailErrorMessage: 'There was an error while fetching a load details'
      };
    },

    getLoadDetails: (state, action) => {
      const { loadNumber, bookId } = action.payload;
      const commodities = action.payload.response;
      let listToUpdate = state.loadsList;
      const indexToUpdate = getIndexToUpdate(listToUpdate, loadNumber, bookId);

      const newValues = {
        loadDetails: {
          ...listToUpdate[indexToUpdate]?.loadDetails,
          commodities: commodities,
        }
      };
      listToUpdate = updateItemInLoadsList(listToUpdate, newValues, indexToUpdate);

      const result = getUpdatedLoadListResult(listToUpdate, state);
      return { ...result, areLoadDetailsLoading: false, };
    },
    getLoadDetailsPending: (state) => {
      return {
        ...state,
        areLoadDetailsLoading: true,
      };
    },
    getLoadDetailsRejected: (state) => {
      return {
        ...state,
        areLoadDetailsLoading: false,
        loadDetailErrorMessage: 'There was an error while fetching a load details'
      };
    },

    getReferenceNumbers: (state, action) => {
      const { loadNumber, bookId } = action.payload;
      let listToUpdate = state.loadsList;
      const indexToUpdate = getIndexToUpdate(listToUpdate, loadNumber, bookId);

      const newValues = {
        loadDetails: {
          ...listToUpdate[indexToUpdate]?.loadDetails,
          ...action.payload.response,
        }
      };
      listToUpdate = updateItemInLoadsList(listToUpdate, newValues, indexToUpdate);

      const result = getUpdatedLoadListResult(listToUpdate, state);
      return { ...result, areReferenceNumbersLoading: false, };
    },
    getReferenceNumbersPending: (state) => {
      return {
        ...state,
        areReferenceNumbersLoading: true,
      };
    },
    getReferenceNumbersRejected: (state) => {
      return {
        ...state,
        areReferenceNumbersLoading: false,
        referenceNumberErrorMessage: 'There was an error while fetching reference numbers'
      };
    },
    getApprovedChargesPending: (state) => {
      return {
        ...state,
        areChargesLoading: true,
      };
    },
    getApprovedChargesRejected: (state) => {
      return {
        ...state,
        areChargesLoading: false,
        referenceNumberErrorMessage: 'There was an error while fetching cost charges'
      };
    },
    getApprovedCharges: (state, action) => {
      const { loadNumber, bookId } = action.payload;
      let listToUpdate = state.loadsList;
      const indexToUpdate = getIndexToUpdate(listToUpdate, loadNumber, bookId);

      const newValues = {
        loadDetails: {
          ...listToUpdate[indexToUpdate]?.loadDetails,
          ...action.payload.response,
        }
      };
      listToUpdate = updateItemInLoadsList(listToUpdate, newValues, indexToUpdate);

      const result = getUpdatedLoadListResult(listToUpdate, state);
      return { ...result, areChargesLoading: false, };
    },

    getDriverLogs: (state, action) => {
      const { loadNumber, bookId } = action.payload;
      let listToUpdate = state.loadsList;
      const indexToUpdate = getIndexToUpdate(listToUpdate, loadNumber, bookId);

      const newValues = {
        driver: {
          ...listToUpdate[indexToUpdate]?.driver,
          trackingLogs: action.payload.response,
        }
      };
      listToUpdate = updateItemInLoadsList(listToUpdate, newValues, indexToUpdate);

      const result = getUpdatedLoadListResult(listToUpdate, state);
      return { ...result, areDriverLogsLoading: false, };
    },
    getDriverLogsPending: (state) => {
      return {
        ...state,
        areDriverLogsLoading: true,
      };
    },
    getDriverLogsRejected: (state) => {
      return {
        ...state,
        areDriverLogsLoading: false,
        driverLogsErrorMessage: 'There was an error while fetching reference numbers'
      };
    },

    // Needs to be refactored. just several props are being used
    getExtendedLoad: (state, action) => {
      const { loadNumber, bookId } = action.payload;
      const extendedLoad = action.payload.response;
      let listToUpdate = state.loadsList;
      const indexToUpdate = getIndexToUpdate(listToUpdate, loadNumber, bookId);
      
      const newValues = {
        extendedLoad: extendedLoad
      };
      listToUpdate = updateItemInLoadsList(listToUpdate, newValues, indexToUpdate);

      const result = getUpdatedLoadListResult(listToUpdate, state);
      return { ...result, areLoadDetailsLoading: false, };
    },
    getExtendedLoadPending: (state) => {
      return {
        ...state,
        areLoadDetailsLoading: true,
      };
    },
    getExtendedLoadRejected: (state) => {
      return {
        ...state,
        areLoadDetailsLoading: false,
        loadDetailErrorMessage: 'There was an error while fetching a load details'
      };
    },

    getLoadCashAdvanceOptions: (state, action) => {
      const { loadNumber, bookId } = action.payload;
      const financialsResponse = action.payload.response;
      let listToUpdate = state.loadsList;
      const indexToUpdate = getIndexToUpdate(listToUpdate, loadNumber, bookId);

      const newValues = {
        financials: {
          ...listToUpdate[indexToUpdate]?.financials,
          options: financialsResponse,
        }
      };
      listToUpdate = updateItemInLoadsList(listToUpdate, newValues, indexToUpdate);

      const result = getUpdatedLoadListResult(listToUpdate, state);
      return { ...result, isCashAdvanceLoading: false, };
    },
    getLoadCashAdvanceOptionsPending: (state) => {
      return {
        ...state,
        isCashAdvanceLoading: true,
      };
    },
    getLoadCashAdvanceOptionsRejected: (state) => {
      return {
        ...state,
        isCashAdvanceLoading: false,
        cashAdvanceErrorMessage: 'There was an error while fetching a load details'
      };
    },

    getCashAdvances: (state, action) => {
      const { loadNumber, bookId } = action.payload;
      const financialsResponse = action.payload.response;
      let listToUpdate = state.loadsList;
      const indexToUpdate = getIndexToUpdate(listToUpdate, loadNumber, bookId);

      const newValues = {
        financials: {
          ...listToUpdate[indexToUpdate]?.financials,
          options: financialsResponse,
        }
      };
      listToUpdate = updateItemInLoadsList(listToUpdate, newValues, indexToUpdate);

      const result = getUpdatedLoadListResult(listToUpdate, state);
      return { ...result, isFinancialHistoryLoading: false, };
    },
    getCashAdvancesPending: (state) => {
      return {
        ...state,
        isFinancialHistoryLoading: true,
      };
    },
    getCashAdvancesRejected: (state) => {
      return {
        ...state,
        isFinancialHistoryLoading: false,
        financialHistoryError: 'There was an error while getting a financial history'
      };
    },
    addCashAdvance: (state, action) => {
      const { cashAdvance, loadNumber, bookId } = action.payload;
      let listToUpdate = state.loadsList;
      const indexToUpdate = getIndexToUpdate(listToUpdate, loadNumber, bookId);

      const newValues = {
        financials: {
          ...listToUpdate[indexToUpdate]?.financials,
          financialHistory: [...listToUpdate[indexToUpdate]?.financials?.financialHistory, cashAdvance],
        }
      };
      listToUpdate = updateItemInLoadsList(listToUpdate, newValues, indexToUpdate);

      return {
        ...state,
        loadsList: listToUpdate
      };
    },
    updateLoadTrackingStatus: (state, action) => {
      const trackingNotification: BookTrackingStatusNotification = action.payload;
      let listToUpdate = state.loadsList;
      const indexToUpdate = getIndexToUpdate(listToUpdate, trackingNotification.shipmentId, trackingNotification.bookId);

      if (!isIntermodal(listToUpdate[indexToUpdate]?.bookType)) {
        const newValues = {
          tracking: {
            ...listToUpdate[indexToUpdate]?.tracking,
            trackingMessageCode: trackingNotification?.trackingMessageCode,
            trackingMethodCode: trackingNotification?.trackingMethodType,
            identifierType: (trackingNotification?.identifierType).toString(),
            errorMessage: trackingNotification?.errorMessage,
            bookTrackingStatusId: trackingNotification?.bookTrackingStatusId,
            trackingStatusType: (trackingNotification?.trackingStatusType).toString(),
          }
        };
        listToUpdate = updateItemInLoadsList(listToUpdate, newValues, indexToUpdate);
      }
      return {
        ...state,
        loadsList: listToUpdate
      };
    },

    updateLoad: (state, action) => {
      const { load } = action.payload;
      let listToUpdate = state.loadsList;

      const indexToUpdate = getIndexToUpdate(listToUpdate, load.loadNumber, load.bookId);
      if (indexToUpdate !== -1) {
        listToUpdate = listToUpdate.map((item, index) => {
          if (index === indexToUpdate) {
            return load;
          }
          return item;
        });
      }

      return {
        ...state,
        loadsList: listToUpdate
      };
    },

    createAppointment: (state, action) => {
      const { load, createAppointment } = action.payload;
      const stopId = createAppointment?.stopId;

      const indexToUpdate = getIndexToUpdate(state.loadsList, load.loadNumber, load.bookId);
      let newListToUpdate;

      if (indexToUpdate !== -1) {
        newListToUpdate = state.loadsList.map((item, index) => {
          if (index === indexToUpdate) {
            const updatedStops = item.stops.map((stop) => {
              if (stop.stopId === stopId) {
                return {
                  ...stop,
                 activities: [
                  {
                   ...stop.activities[0],
                   appointment: {
                    ...stop.activities[0].appointment,
                    ...createAppointment
                   }
                  },
                 ],
                };
              }
              return stop;
            });

            return {
              ...item,
              stops: updatedStops,
            };
          }
          return item;
        });
      }

      return {
        ...state,
        loadsList: newListToUpdate
      };
    },

    createAppointmentPending: (state) => {
      return {
        ...state,
        isCreateAppointmentLoading: true,
      };
    },
    createAppointmentRejected: (state) => {
      return {
        ...state,
        isCreateAppointmentLoading: false,
        createAppointmentError: 'There was an error while creating an appointment'
      };
    },
  },
});

// async
export const fetchMyLoads = (searchCriteria: FetchMyLoadsCriteria, currentTab?: string) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.getMyLoadsPending());
  const setStatus = () => {
    if (searchCriteria.status) {
      return searchCriteria.status;
    } else {
      return currentTab === 'completed' ? 10000 : 0;
    }
  };
  return new Promise<string>((resolve, reject) => {
    shipments.getMyLoads({ ...searchCriteria, status: setStatus() }).subscribe(
     (response) => {
       dispatch(myLoadsBaseSlice.actions.getMyLoads(response));

       const allCompleted = response.items.every((load: LoadSummary) => load.loadStatus >= 90);

       let result = '';

       if (allCompleted) {
         result = 'completed';
       } else  {
         result = 'active';
       }
       resolve(result);
     },
     (error) => {
       dispatch(myLoadsBaseSlice.actions.getMyLoadsRejected());
       reject(error);
     }
    );
  });

};

export const getLoadStops = (loadNumber: number, currentTab: string, bookId: string) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.getLoadStopsPending());
  shipments.getStops(loadNumber).subscribe(
    (response) => {
      dispatch(myLoadsBaseSlice.actions.getLoadStops({ response, loadNumber, currentTab, bookId }));
    },
    (error) => {
      dispatch(myLoadsBaseSlice.actions.getLoadStopsRejected());
    }
  );
};

export const getMyLoadDetails = (loadNumber: number, currentTab: string, bookId: string) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.getMyLoadDetailsPending());
  shipments.getMyLoadDetails(loadNumber).subscribe(
    (response) => {
      dispatch(myLoadsBaseSlice.actions.getMyLoadDetails({ response, loadNumber, currentTab, bookId }));
    },
    (error) => {
      dispatch(myLoadsBaseSlice.actions.getMyLoadDetailsRejected());
    }
  );
};

export const getLoadDetails = (loadNumber: number, currentTab: string, bookId: string) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.getLoadDetailsPending());
  shipments.getLoadDetails(loadNumber).subscribe(
    (response) => {
      dispatch(myLoadsBaseSlice.actions.getLoadDetails({ response, loadNumber, currentTab, bookId }));
    },
    (error) => {
      dispatch(myLoadsBaseSlice.actions.getLoadDetailsRejected());
    }
  );
};

export const getLoadReferenceNumbers = (loadNumber: number, currentTab: string, bookId: string) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.getReferenceNumbersPending());
  shipments.getReferenceNumbers(loadNumber).subscribe(
    (response) => {
      dispatch(myLoadsBaseSlice.actions.getReferenceNumbers({ response, loadNumber, currentTab, bookId }));
    },
    (error) => {
      dispatch(myLoadsBaseSlice.actions.getReferenceNumbersRejected());
    }
  );
};

export const getApprovedCharges = (loadNumber: number, bookId: string) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.getApprovedChargesPending());
  shipments.getApprovedCharges(loadNumber).subscribe(
    (response) => {
      dispatch(myLoadsBaseSlice.actions.getApprovedCharges({ response, loadNumber, bookId}));
    },
    (error) => {
      dispatch(myLoadsBaseSlice.actions.getApprovedChargesRejected());
      throw error;
    }
  );
};

export const getDriverLogs = (loadNumber: number, currentTab: string, sequenceNumber: string) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.getDriverLogsPending());
  myLoadsRepository.getLoadDriverLogs(loadNumber, parseInt(sequenceNumber, 0)).subscribe(
    (response) => {
      dispatch(myLoadsBaseSlice.actions.getDriverLogs({ response, loadNumber, currentTab, bookId: sequenceNumber }));
    },
    (error) => {
      dispatch(myLoadsBaseSlice.actions.getDriverLogsRejected());
    }
  );
};

export const getExtendedLoad = (loadNumber: number, carrierCode: string, currentTab: string, bookId: string) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.getExtendedLoadPending());
  interim.getLoad(loadNumber, null, carrierCode).subscribe(
    (response) => {
      dispatch(myLoadsBaseSlice.actions.getExtendedLoad({ response, loadNumber, currentTab, bookId }));
      dispatch(setLoadDetails(response));
    },
    (error) => {
      dispatch(myLoadsBaseSlice.actions.getExtendedLoadRejected());
    }
  );
};

export const getLoadCashAdvancesOptions = (loadNumber: number, bookSequenceNumber: string, currentTab: string) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.getLoadCashAdvanceOptionsPending());
  financials.getCashAdvanceOptions(loadNumber, parseInt(bookSequenceNumber, 10)).subscribe(
    (response) => {
      dispatch(myLoadsBaseSlice.actions.getLoadCashAdvanceOptions({ response, loadNumber, currentTab, bookId: bookSequenceNumber }));
      dispatch(fetchCashAdvanceOptionsSuccess(response));
    },
    (error) => {
      dispatch(myLoadsBaseSlice.actions.getLoadCashAdvanceOptionsRejected());
    }
  );
};

export const getLoadCashAdvances = (loadNumber: number, bookSequenceNumber: string, currentTab: string) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.getCashAdvancesPending());
  financials.getCashAdvanceHistory(loadNumber, parseInt(bookSequenceNumber, 10)).subscribe(
    (response) => {
      dispatch(myLoadsBaseSlice.actions.getCashAdvances({ response, loadNumber, currentTab, bookId: bookSequenceNumber }));
    },
    (error) => {
      dispatch(myLoadsBaseSlice.actions.getCashAdvancesRejected());
    }
  );
};

export const updateLoad = (load: LoadSummary) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.updateLoad({ load }));
};

export const addCashAdvance = (cashAdvance: CashAdvances, loadNumber: string, bookId: string) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.addCashAdvance({ cashAdvance: cashAdvance, loadNumber: loadNumber, bookId: bookId }));
};

export const setLoadPendingStatus = (loadNumber: number, bookId: string) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.setLoadPendingState({loadNumber, bookId}));
};

export const updateLoadInformation = (bookLoadUpdateNotification: BookLoadUpdateNotification) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.updateLoadInformation(bookLoadUpdateNotification));
  dispatch(myLoadsBaseSlice.actions.setLoadSavedState({
    loadNumber: bookLoadUpdateNotification?.shipmentId,
    bookId: bookLoadUpdateNotification?.bookId
  }));
};

export const updateLoadTrackingStatus = (bookTrackingStatusNotification: BookTrackingStatusNotification) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.updateLoadTrackingStatus(bookTrackingStatusNotification));
};

export const createAppointment = (load: LoadSummary, createAppointment: CreateAppointment) => dispatch => {
  dispatch(myLoadsBaseSlice.actions.createAppointment({load, createAppointment}));
};

export default myLoadsBaseSlice.reducer;
