import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'shared/enums/sort-order.enum';

interface Props {
  className: string;
  sortOrder: SortOrder;
  onClick: (e: MouseEvent<HTMLButtonElement>) => any;
  sortOrderDisabled?: boolean;
}

export const CardSortOrderToggle = ({ className, sortOrder, sortOrderDisabled, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <button onClick={onClick} className={className + ' sort-order-toggle'} disabled={sortOrderDisabled} aria-live="polite">
      <span className="visually-hidden">{t('SORT_IN_ORDER_OF')}{' '}</span>
      {sortOrder === SortOrder.ASC
        ?
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
          </svg>
          {t('ASCENDING')}
        </>
        :
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path fill="#010101" d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
          </svg>
          {t('DESCENDING')}
        </>
      }
    </button>
  );
};