import { Dispatch, SetStateAction } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import { ManageUsersViews, setPageNumber } from 'features/account-settings';
import { useTranslation } from 'react-i18next';
import * as Styles from 'features/account-settings/manage-users/styles';
import { useDispatch } from 'react-redux';

export const BreadcrumbsComponent = ({ setView, view }: { setView: Dispatch<SetStateAction<symbol>>, view: string }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
  
    const handleBackToManageUsers = () => {
      setView(ManageUsersViews.MANAGE_USERS);
      dispatch(setPageNumber(1)); // Reset page if necessary
    };
  
    return (
      <Breadcrumbs>
        <Button
          sx={Styles.BreadCrumbButton}
          variant="text"
          onClick={handleBackToManageUsers}
          data-testid="manage-users-breadcrumb"
        >
          {t('MANAGE_USERS')}
        </Button>
        <Button disabled sx={Styles.BreadCrumbButton} variant="text">
          {view}
        </Button>
      </Breadcrumbs>
    );
  };