import classNames from 'classnames';
import { ChangeEvent } from 'react';
import { DataViewFormat } from 'shared/enums/data-view.enum';
import { Label, Option } from 'shared/components/elements/elements.components';
import { CardSortOption } from 'shared/components/data-table/data-table.interfaces';

interface Props {
  className?: string;
  id?: string;
  viewFormat: DataViewFormat;
  sortByColumnName: string;
  sortByEvent: (event: ChangeEvent<HTMLSelectElement>) => void;
  cardSortOptions: CardSortOption[];
  includeEmptyOption?: boolean;
}

export const CardSortSelect = ({ className, id, viewFormat, sortByColumnName, sortByEvent, cardSortOptions, includeEmptyOption = true }: Props) =>
  <div className={classNames(className, 'card-sorting')}>
    <Label className="space-outer-right-xs" htmlFor={'card-sorting' + (id ? `_${id}` : '')} resource="SORT_BY" />
    <select
      id={'card-sorting' + (id ? `_${id}` : '')}
      value={sortByColumnName}
      name="card-sorting"
      onChange={sortByEvent}
    >
      {includeEmptyOption && <option className="js-empty-option" />}
      {Boolean(cardSortOptions) && cardSortOptions.map(sortOption =>
        <Option
          key={sortOption.resourceKey}
          value={sortOption.field}
          resource={sortOption.resourceKey}
        />
      )}
    </select>
  </div>;
